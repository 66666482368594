import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import { useModal, useRadiologyGroup, useSnackbar, useUser } from '../../contextProviders';
import RadiologistGroupModel from './RadiologistGroupModel';
import Swal from 'sweetalert2';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";

// header
import { useAppConfig } from '@state';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Button, Header } from '@ohif/ui';
import RadiologyGroupMember from './RadiologyGroupMember';


const RadiologyGroupUser = ({ servicesManager, extensionManager, hotkeysManager }) => {

  const {
    fetchRadiologyGroup,
    radiologyGroupList,
    setRadiologyGroupList,
    createRadiologyGroup,
    deleteRadiologyGroup,
    updateRadiologyGroup,
    fetchSingleRadiologyGroup,
    singleRadiologyGroupList,
    setSingleRadiologyGroupList,
    setGroupIds,
    groupIds,
    viewRadiologyGroupMember,
    setRadiologyGroupMemberList,
    deleteRadiologyGroupMember
  } = useRadiologyGroup();

  const { show, hide } = useModal();
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();
  const {token, userToken, setToken} = useUser();
  const permissions = user?.profile?.permission?.[0]
    ?.split(',')
    .map(permission => permission.trim());
  const hasManagerPermission =
    permissions?.includes('Create Radiology Group') ||
    permissions?.includes('Add Radiology Group Admin') ||
    token?.realm_access?.roles.includes('super-admin') ||
    token?.realm_access?.roles.includes('deputy-admin');

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const tableHeaders = {
    name: 'Radiology Group Name',
    email: 'Email ID',
    mobileNumber: 'Mobile Number',
    aeTitle: 'AE Title',
    Ip: 'IP Address',
    portNumber: 'Port Number',
    address: 'Address',
    permission: 'Permission',
    member: 'Member',
    // createdTimestamp: 'CreatedAt',
    update: 'Update',
    delete: 'Delete',
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  const [radiologyGroups, setRadiologyGroups] = useState([])

  useEffect(() => {
    const filteredGroupIds = radiologyGroupList
      .filter(group => !group.error)
      .map(group => group.id);

    setGroupIds(filteredGroupIds);
  }, [deleteRadiologyGroup, radiologyGroupList]);



  const handleDelete = async (id) => {

    Swal.fire({
      text: 'Are you sure to delete this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteRadiologyGroup(id, user.access_token)
          .then(async (res) => {
            Swal.fire({
              title: 'Deleted',
              text: 'User has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false
            });
            if (res.status === 204) {
              await fetchSingleRadiologyGroup(user.access_token, groupIds)
                .then(data => setSingleRadiologyGroupList(data))
                .catch(error => console.error('Error fetching users:', error));

              await fetchRadiologyGroup(user.access_token)
                .then(data => setRadiologyGroupList(data))
                .catch(error => console.error('Error fetching users:', error));
            }
          })
          .catch((error) => {
            console.error('Error deleting user:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete user. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          text: 'User Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK'
        });
      }
    });
  }

  const handlerWarning = async () => {
    Swal.fire({
      title: 'Authorization Required',
      text: 'Only super-admin, deputy-admin, and telerapp-manager roles are authorized to create and update radiologist groups.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      background: '#f2f2f2',

    }).then(async result => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Action Cancelled',
          text: 'The deletion of the user has been cancelled.',
          icon: 'info',
          confirmButtonText: 'Understood',
          background: '#e0f7fa',
        });
      }
    });
  };

  const modelShow = (data) => {

    show({
      content: RadiologistGroupModel,
      title: `${data ? 'Update Radiologist Group' : 'Create Radiologist Group'}`,
      contentProps: { data: data.name ? data : '', user, hide, createRadiologyGroup, fetchRadiologyGroup, updateRadiologyGroup, setRadiologyGroupList, fetchSingleRadiologyGroup, singleRadiologyGroupList, setSingleRadiologyGroupList, groupIds, radiologyGroupList },
    })
  }

  const handleMembers = (id) => {

    show({
      content: RadiologyGroupMember,
      title: `Radiology Group Members`,
      contentProps: {user, token, groupId: id, viewRadiologyGroupMember, setRadiologyGroupMemberList, deleteRadiologyGroupMember, hide},
    })
  }

  useEffect(() => {
    const radiologyGroupsListss = singleRadiologyGroupList.filter(group => !group.error);
    setRadiologyGroups(radiologyGroupsListss);
  }, [singleRadiologyGroupList]);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  // Header
  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <section>
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        hotkeysManager={hotkeysManager}
      />
      <div className='container relative mx-auto flex flex-col pt-5'>
        <div className="mb-5 flex flex-row justify-between">
          <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
            <Typography
              variant="h6"
              className="text-white"
              component={'h6'}
            >
              Radiology Group
            </Typography>

          </div>
          <div className="flex h-[34px] flex-row items-center">

            <Typography
              variant="h6"
              className="text-primary-light cursor-pointer"
              component={'h6'}
              onClick={hasManagerPermission ? modelShow : handlerWarning}
            >
              Add Radiology Group
            </Typography>
          </div>
        </div>
      </div>

      <table className="mt-2 table-auto text-white container m-auto">
        <thead className="bg-primary-dark border-secondary-light border">
          <tr>
            {values.map(tableHead => (
              <th
                className="p-2"
                key={'tableHead'}
              >
                {tableHead}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mt-1 ">
          {radiologyGroups && radiologyGroups?.map(instance => (
            <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border transition duration-300">
              {keys.map((key) => (
                <td className="p-2" key={key}>
                  {key === "delete" ? (
                    <div className="cursor-pointer transition-all hover:text-red-400 text-xl text-[#ff0000] flex justify-center items-center" onClick={() => hasManagerPermission ? handleDelete(instance.id) : handlerWarning()}>
                      <RiDeleteBin6Fill />
                    </div>
                  ) : key === "update" ? (
                    <div className="cursor-pointer transition-all hover:text-[#63b3ed] text-xl flex justify-center items-center"  onClick={() => hasManagerPermission ? modelShow(instance) : handlerWarning()}>
                      <FaEdit />
                    </div>
                  ) : key === "aeTitle" ? (
                    <span className='cursor-pointer'>
                      {instance?.attributes?.groupAETitle}
                    </span>
                  ) : key === "Ip" ? (
                    <span className='cursor-pointer'>
                      {instance?.attributes?.groupIP}
                    </span>
                  ) : key === "portNumber" ? (
                    <span className='cursor-pointer'>
                      {instance?.attributes?.groupPortNumber}
                    </span>
                  ) : key === "address" ? (
                    <span className='cursor-pointer'>
                      {instance?.attributes?.groupAddress}
                    </span>
                  ) : key === "email" ? (
                    <span className='cursor-pointer'>
                      {instance?.attributes?.groupEmail}
                    </span>
                  ) : key === "mobileNumber" ? (
                    <span className='cursor-pointer' onClick={() => modelShow(instance)}>
                      {instance?.attributes?.groupMobileNumber}
                    </span>
                  ) : key === "permission" ? (
                    <span className='cursor-pointer' onClick={() => modelShow(instance)}>
                      {instance?.attributes?.groupPermission?.join(', ')}
                    </span>
                  ) : key === "member" ? (
                    <span className='cursor-pointer'>
                      <Button className='cursor-pointer' onClick={() => handleMembers(instance.id)}>
                      View Member
                    </Button>
                    </span>
                  ) : (
                    instance[key]
                  )}
                </td>
              ))
              }
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default RadiologyGroupUser;
