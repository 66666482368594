import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

import Typography from '../Typography';
import { useModal, useRadiologyGroup, useUser } from '../../contextProviders';
import { Header } from '@ohif/ui';
import { useAppConfig } from '@state';
import TechnologistCreateModel from './TechnologistCreateModel';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";


const Technologist = ({ servicesManager, extensionManager, hotkeysManager }) => {
  const { usersList, createUser, updateUser, fetchUsers, setUsersList, deleteUser, token, userToken, setToken } = useUser();

  const { radiologyGroupList, addRadiologyGroupMember, deleteRadiologyGroupMember } =
    useRadiologyGroup();

  const tableHeaders = {
    firstName: 'FirstName',
    lastName: 'LastName',
    mobileNumber: 'Mobile Number',
    email: 'Email',
    username: 'Username',
    title: 'Title',
    permission: 'Permission',
    radiologyGroup: 'Radiology Group',
    createdTimestamp: 'CreatedAt',
    update: 'Update',
    delete: 'Delete',
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);
  const { userAuthenticationService } = servicesManager.services;
  const { show, hide } = useModal();
  const user = userAuthenticationService.getUser();

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const handleDelete = async id => {
    Swal.fire({
      // title: 'Are you sure?',
      text: 'Are you sure to delete this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        // If the user confirms deletion, proceed with the deletion
        await deleteUser(id, user.access_token)
          .then(async res => {
            Swal.fire({
              title: 'Deleted',
              text: 'User has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            if (res.status === 204) {
              await fetchUsers(user.access_token)
                .then(data => setUsersList(data))
                .catch(error => console.error('Error fetching users:', error));
            }
          })
          .catch(error => {
            console.error('Error deleting user:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete user. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          // title: 'Cancelled',
          text: 'User Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK',
        });
      }
    });
  };

  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const modelShow = technologistUserData => {
    show({
      content: TechnologistCreateModel,
      title: `TechnologistCreateModel: ${technologistUserData.username ? 'Update Technologist User' : 'Create New Technologist User'} `,
      contentProps: {
        user,
        hide,
        technologistUserData: technologistUserData.username ? technologistUserData : '',
        radiologyGroupList,
        addRadiologyGroupMember,
        deleteRadiologyGroupMember,
        createUser,
        updateUser,
        fetchUsers,
        setUsersList,
      },
    });
  };

  const technologistUserLists =
    usersList &&
    usersList?.filter(
      user =>
        user.attributes &&
        user.attributes.roleType &&
        user.attributes.roleType.includes('Technologist')
    );

  return (
    <section className="">
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        hotkeysManager={hotkeysManager}
      />
      <ToastContainer />
      <div className="container relative mx-auto flex flex-col pt-5">
        <div className="mb-5 flex flex-row justify-between">
          <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
            <Typography
              variant="h6"
              className="text-white"
              component={'h6'}
            >
              Technologist Users
            </Typography>
          </div>
          <div className="flex h-[34px] flex-row items-center">
            <Typography
              variant="h6"
              className="text-primary-light cursor-pointer"
              component={'h6'}
              onClick={modelShow}
            >
              Add Technologist
            </Typography>
          </div>
        </div>
      </div>
      <table className="container m-auto mt-2 table-auto text-white relative">
        <thead className="bg-primary-dark border-secondary-light relative border">
          <tr>
            {values.map(tableHead => (
              <th
                className="p-2"
                key={'tableHead'}
              >
                {tableHead}
              </th>
            ))}
          </tr>
        </thead>

        {technologistUserLists && technologistUserLists.length <= 0 ? (
          <span className="absolute  flex h-96 w-full items-center justify-center">
            No Technologist User Exiting
          </span>
        ) : (
          <>
            <tbody className="mt-1 ">
              {technologistUserLists &&
                technologistUserLists?.map(instance => (
                  <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                    {keys.map(key => (
                      <td
                        className="p-2"
                        key={key}
                      >
                        {key === 'delete' ? (
                          <div
                            className="cursor-pointer transition-all hover:text-red-400 text-xl text-[#ff0000] flex justify-center items-center"
                            onClick={() => handleDelete(instance.id)}
                          >
                             <RiDeleteBin6Fill />
                          </div>
                        ) : key === 'title' ? (
                          <span className="cursor-pointer">
                            {instance?.attributes?.title?.join(', ')}
                          </span>
                        ) : key === 'update' ? (
                          <div
                            className="cursor-pointer transition-all hover:text-[#63b3ed] text-xl flex justify-center items-center"
                            onClick={() => modelShow(instance)}
                          >
                            <FaEdit />
                          </div>
                        ) : key === 'radiologyGroup' ? (
                          <span
                            className="cursor-pointer"
                            onClick={() => modelShow(instance)}
                          >
                            {instance?.attributes?.radiologyGroup?.join(', ')}
                          </span>
                        ) : key === 'permission' ? (
                          <span
                            className="cursor-pointer"
                            onClick={() => modelShow(instance)}
                          >
                            {instance?.attributes?.permission?.join(', ')}
                          </span>
                        ) : key === 'mobileNumber' ? (
                          <span className="cursor-pointer">
                            {instance?.attributes?.mobileNumber?.join(', ')}
                          </span>
                        ) : key === 'createdTimestamp' ? (
                          <span className="cursor-pointer">
                            {moment(instance.createdTimestamp).format('MM/DD/YYYY hh:mm:ss A')}
                          </span>
                        ) : (
                          instance[key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </>
        )}
      </table>
    </section>
  );
};

export default Technologist;
