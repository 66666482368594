import React, { useState, useEffect } from 'react';
import './WorklistModel.css';
import Button from '../Button';
import { CSVDownload } from 'react-csv';
import Select from '../Select';
import { utils, writeFile } from 'xlsx';
import { useModal, useUser } from '../../contextProviders';
import filtersMeta from '../../../../app/src/routes/WorkList/filtersMeta';
import { useAppConfig } from '@state';
import { useNavigate } from 'react-router-dom';
import { Header } from '@ohif/ui';
import { RxCross2 } from "react-icons/rx";

const WorklistModel = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
  const [appConfig] = useAppConfig();
  const [checkedAttributesItems, setCheckedAttributesItems] = useState({
    general: true,
    checkBox: true,
  });
  const [checkedViewerItems, setCheckedViewerItems] = useState({});
  const [csvData, setCsvData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState(null);

  const {
    createWorklistAttributes,
    worklistAttributesList,
    updateWorklistAttributes,
    fetchWorklistAttributes,
    setWorklistAttributesList,
    token,
  } = useUser();

  const { userAuthenticationService } = servicesManager?.services || {};

  const user = userAuthenticationService?.getUser();
// console.log(user?.profile, 'user')
  const navigate = useNavigate();

  // ~ Worklist Attributes Setting
  const userCurrentWorklist = worklistAttributesList
    ? worklistAttributesList?.find(item => user && user?.profile?.sub && item.user_id === user.profile.sub)
    : null;

  const attributesName = filtersMeta
    .filter(item => item.name !== 'checkBox' && item.name !== 'general')
    .map(item => ({
      name: item.name,
      displayName: item.displayName,
    }));

  const viewerName = appConfig.loadedModes.map(item => ({
    routeName: item.routeName,
    displayName: item.displayName,
  }));

  const sortedStudies = studies;

  const permissions = user && user?.profile?.permission && user?.profile?.permission?.[0]
    ?.split(',')
    .map(permission => permission.trim());

  const hasExportWroklistPermission =
    permissions?.includes('Export Worklist') ||
    token?.realm_access?.roles.includes('super-admin') ||
    token?.realm_access?.roles.includes('deputy-admin');

  const options = [
    { label: 'CSV', value: 'csv' },
    { label: 'Excel', value: 'excel' },
  ];

  useEffect(() => {
    if (userCurrentWorklist) {
      const attributesObj = JSON.parse(userCurrentWorklist.worklistAttributes[0]);
      setCheckedAttributesItems(attributesObj);
      const viewerObj = JSON.parse(userCurrentWorklist.viewer[0]);
      setCheckedViewerItems(viewerObj);
    } else {
      setCheckedAttributesItems({
        general: true,
        checkBox: true,
      });
    }
  }, [userCurrentWorklist]);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const handleNameClick = async name => {
    const updatedCheckedItems = {
      ...checkedAttributesItems,
      [name]: !checkedAttributesItems[name],
    };
    setCheckedAttributesItems(updatedCheckedItems);
  };

  const handleViewer = async name => {
    const updatedCheckedItems = { ...checkedViewerItems, [name]: !checkedViewerItems[name] };
    setCheckedViewerItems(updatedCheckedItems);
  };

  const handleSave = async () => {
    const data = {
      worklistAttributes: [JSON.stringify(checkedAttributesItems)],
      viewer: [JSON.stringify(checkedViewerItems)],
      user_id: user && user?.profile?.sub && user?.profile?.sub,
    };

    if (userCurrentWorklist) {
      const response = await updateWorklistAttributes(userCurrentWorklist.id, data);
      if (response.status === 200) {
        await fetchWorklistAttributes()
          .then(data => setWorklistAttributesList(data))
          .catch(error => console.error('Error fetching users:', error));
        navigate('/');
      }
    } else {
      const response = await createWorklistAttributes(data);
      if (response.status === 201) {
        await fetchWorklistAttributes()
          .then(data => setWorklistAttributesList(data))
          .catch(error => console.error('Error fetching users:', error));
        navigate('/');
      }
    }
  };

  const handleclose = () => {
    navigate('/');
  };

  const handleSelectChange = selected => {
    setSelectedOption(selected.value);
  };

  const header = Object.keys(sortedStudies[0] || []);

  const handleDownload = () => {
    if (selectedOption === 'csv') {
      const csvData = [
        header,
        ...sortedStudies.map(study => [
          study.studyInstanceUid,
          study.date,
          '', // Assuming "time" field is empty for now
          study.accession,
          study.mrn,
          study.patientId,
          study.patientSex,
          study.patientName,
          study.instances,
          study.description,
          study.referringPhysician,
          study.institutionName,
          study.modalities,
          study.age,
        ]),
      ];
      setCsvData(csvData);
      setError('');
    } else if (selectedOption === 'excel') {
      const xlData = [
        header,
        ...sortedStudies.map(study => [
          study.studyInstanceUid,
          study.date,
          '', // Assuming "time" field is empty for now
          study.accession,
          study.mrn,
          study.patientId,
          study.patientSex,
          study.patientName,
          study.instances,
          study.description,
          study.referringPhysician,
          study.institutionName,
          study.modalities,
          study.age,
        ]),
      ];

      const ws = utils.aoa_to_sheet(xlData);
      const columnWidths = [
        { wch: 55 }, // Width for studyInstanceUid column
        { wch: 12 }, // Width for date column
        { wch: 10 }, // Width for time column (empty)
        { wch: 15 }, // Width for accession column
        { wch: 15 }, // Width for mrn column
        { wch: 15 }, // Width for patientId column
        { wch: 10 }, // Width for patientSex column
        { wch: 45 }, // Width for patientName column
        { wch: 10 }, // Width for instances column
        { wch: 30 }, // Width for description column
        { wch: 30 }, // Width for referringPhysician column
        { wch: 30 }, // Width for institutionName column
        { wch: 15 }, // Width for modalities column
        { wch: 8 }, // Width for age column
      ];

      columnWidths.forEach((width, colIndex) => {
        ws['!cols'] = ws['!cols'] || [];
        ws['!cols'][colIndex] = width;
      });

      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1');
      writeFile(wb, 'Worklist.xlsx');
    } else if (!selectedOption) {
      setError('Select Any one option');
    }
  };

  return (
    <section className="">
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        hotkeysManager={hotkeysManager}
      />
      {/* Attributes */}
      <div className="ReactModal__Overlay ReactModal__Overlay--after-open bg-overlay fixed top-0 left-0 right-0 bottom-0 z-50 flex items-start justify-center py-16">
        <div className=" ReactModal__Content ReactModal__Content--after-open relative max-h-full w-11/12 text-white outline-none lg:w-10/12  xl:w-1/2">
          <header className=" bg-primary-dark flex items-center rounded-tl rounded-tr px-[20px] py-[13px]">
            <h6 className="  text-primary-light m-0 flex grow text-xl leading-tight !leading-[1.2]">
              Worklist Option
            </h6>
            <RxCross2 onClick={handleclose} className=' text-primary-light text-xl' />
          </header>
          <div className=" telerapp-scrollbar modal-content bg-primary-dark overflow-y-auto rounded-bl rounded-br px-[20px] pt-2 pb-[20px]">
            <div>
              <h3 className="headingMaindesign">Select Worklist Fields</h3>
              <div className="worklistfields flex justify-center">
                <div className="grid grid-cols-2 gap-10 ">
                  <div>
                    <h3 className="hedingdesign text-primary-light">Attributes</h3>
                    {attributesName
                      .filter(item => item.name !== 'checkBox' && item.name !== 'general')
                      .map((data, index) => (
                        <div
                          className="namebox"
                          key={index}
                          onClick={() => handleNameClick(data.name)}
                        >
                          <input
                            type="checkbox"
                            checked={checkedAttributesItems[data.name] || false}
                            onChange={() => handleNameClick(data.name)}
                          />
                          <span onClick={() => handleNameClick(data.name)}>{data.displayName}</span>
                        </div>
                      ))}
                  </div>

                  {/* Viewer */}
                  <div>
                    <h3 className="hedingdesign text-primary-light">Viewer</h3>
                    {viewerName.map((data, index) => (
                      <div
                        className="namebox"
                        key={index}
                        onClick={() => handleViewer(data.routeName)}
                      >
                        <input
                          type="checkbox"
                          checked={checkedViewerItems[data.routeName] || false}
                          onChange={() => handleViewer(data.routeName)}
                        />
                        <span onClick={() => handleViewer(data.routeName)}>{data.displayName}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {hasExportWroklistPermission ? (
                <div className="mt-5 flex flex-row justify-between ">
                  <div className="mb-5 flex gap-2">
                    <div className="w-28">
                      <Select
                        id="display-set-selector"
                        isClearable={false}
                        onChange={handleSelectChange}
                        options={options}
                        value={[selectedOption]}
                        className="text-white "
                      />
                    </div>
                    <Button onClick={handleDownload}>Export Worklist</Button>
                    <div className="absolute bottom-2.5 text-red-600">{error}</div>
                  </div>

                  {csvData && (
                    <CSVDownload
                      data={csvData}
                      target="_blank"
                    />
                  )}
                  <div className="flex gap-2">
                    <Button onClick={handleclose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                  </div>
                </div>
              ) : (
                <div className="mt-5 flex items-center justify-between ">
                  <Button onClick={handleclose}>Cancel</Button>
                  <Button onClick={handleSave}>Save</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WorklistModel;
