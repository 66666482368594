import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import getGridWidthClass from '../../utils/getGridWidthClass';
import { useNavigate } from 'react-router-dom';

import Icon from '../Icon';

const StudyListTableRow = props => {
  const navigate = useNavigate();
  const { tableData, filteredModes, dataPath, filterValues } = props;
  const { row, expandedContent, onClickRow, isExpanded, dataCY, findData, study } = tableData;

  const handleDoubleClick = (event) => {
    filteredModes.map((mode, i) => {

      const modalitiesToCheck = study?.modalities?.replaceAll('/', '\\');

      const isValidMode = mode.isValidMode({
        modalities: modalitiesToCheck,
        study,
      });

      const query = new URLSearchParams();
      if (filterValues.configUrl) {
        query.append('configUrl', filterValues.configUrl);
      }
      query.append('StudyInstanceUIDs', study?.studyInstanceUid);
      query.append('UUID', study?.studyID);

      if (mode.displayName === 'Telerapp Viewer' && isValidMode) {
        event.preventDefault();
        const url = `${dataPath ? '../../' : ''}${mode.routeName}${dataPath || ''}?${query?.toString()}`;
        navigate(url)
      }
    })
  };

  return (
    <>
      <tr
        className="select-none"
        data-cy={dataCY}
      >
        <td
          className={classnames('border-0 p-0', {
            'border-secondary-light bg-primary-dark border-b': isExpanded,
          })}
        >
          <div
            className={classnames(
              'w-full transition duration-300',
              {
                'border-primary-light hover:border-secondary-light mb-2 overflow-hidden rounded border':
                  isExpanded,
              },
              {
                'border-transparent': !isExpanded,
              }
            )}
          >
            <table className={classnames('w-full p-4')}>
              <tbody className='bg-primary-dark'>
                <tr
                  style={{
                    display: 'grid',

                    gridTemplateColumns: 'repeat(25, 1fr)',

                  }}
                >
                  {row.map((cell, index) => {
                    const { content, title, gridCol, key } = cell;
                    return (
                      <td
                        key={index}
                        className={classnames(
                          'px-4 py-2 text-base cursor-pointer flex items-center	',
                          { 'border-secondary-light border-b': !isExpanded },
                          { 'truncate': key !== 'priority' },
                          { 'isHover': key === 'patientName' && findData[0]?.critical ? false : true },
                          { 'isHoverRed ': findData[0]?.study_priority === 'ASAP' },
                          { 'isHoverGreen': findData[0]?.study_priority === 'STAT' },
                          { 'flex': ['share', 'attachment', 'clinicalhistory', 'reportstatus', 'modality', 'studyTat'].includes(key) },
                          { 'justify-center': ['share', 'attachment', 'clinicalhistory', 'reportstatus', 'modality', 'studyTat'].includes(key) },
                          { 'items-center': ['share', 'attachment', 'clinicalhistory', 'reportstatus', 'modality', 'studyTat'].includes(key) },
                        )}

                        style={{
                          width: key === 'checkBox' ? '100px' : key === 'document' ? '209px' : key === 'patientName' ? '300px' : key === 'studyDate' ? '210px' : key === 'description' ? '245px' : key === 'institutionname' ? '200px' : key === 'lastupdate' ? '205px' : key === 'instances' ? '120px' : key === 'share' ? '100px' : key === 'attachment' ? '104px' : key === 'clinicalhistory' ? '126px' : key === 'reportstatus' ? '125px' : key === 'modality' ? '100px' : key === 'age' ? '85px' : key === 'sex' ? '85px' : key === 'dob' ? '100px' : key === 'tat' ? '130px' : key === 'studyTat' ? '100px' : key === 'assign' ? '120px' : '180px',
                          backgroundColor: key === 'patientName' && findData[0]?.critical
                            ? '#63B3ED'
                            : findData[0]?.study_priority === 'STAT'
                              ? 'rgba(139, 0, 0, 0.3)'
                              : findData[0]?.study_priority === 'ASAP'
                                ? 'rgba(0, 100, 0, 0.3)'
                                : '',
                          color: key === 'patientName' && findData[0]?.critical ? "black" : ''
                        }}
                        title={title}
                        onDoubleClick={['share', 'delete', 'document', 'reportstatus', 'assign', 'attachment', 'clinicalhistory', 'priority', 'checkBox'].includes(key) ? () => { } : handleDoubleClick}
                        onClick={['share', 'delete', 'document', 'reportstatus', 'assign', 'attachment', 'clinicalhistory', 'priority', 'checkBox'].includes(key) ? () => { } : onClickRow}
                      >
                        <div className="flex">
                          {index === 0 && (<>
                            {/* <input
                              type="checkbox"
                              name="signature"
                              id='signature'
                             checked={worklistData[key]}
                            /> */}
                            <div onClick={onClickRow}>
                              <Icon
                                name={isExpanded ? 'chevron-down' : 'chevron-right'}
                                className="mr-4 inline-flex"
                              />
                            </div>
                          </>
                          )}
                          <div
                            className={classnames({ 'overflow-hidden': key === 'priority' ? false : true }, { truncate: key === 'priority' ? false : true })}
                          >
                            {content}
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
                {isExpanded && (
                  <tr className="max-h-0 w-full select-text overflow-hidden bg-black">
                    <td colSpan={row.length}>{expandedContent}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

StudyListTableRow.propTypes = {
  tableData: PropTypes.shape({
    /** A table row represented by an array of "cell" objects */
    row: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        /** Optional content to render in row's cell */
        content: PropTypes.node,
        /** Title attribute to use for provided content */
        title: PropTypes.string,
        gridCol: PropTypes.number.isRequired,
      })
    ).isRequired,
    expandedContent: PropTypes.node.isRequired,
    onClickRow: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    dataCY: PropTypes.string,
  }),
  filteredModes: PropTypes.any,
  dataPath: PropTypes.any,
  filterValues: PropTypes.any,
};

export default StudyListTableRow;
