import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { useAppConfig } from '@state';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary, UserPreferences, AboutModal, Header, Button, useModal, useUser } from '@ohif/ui';
import { hotkeys } from '@ohif/core';
import i18n from '@ohif/i18n';
import { MdAccountBalance, MdOutlineSettingsBrightness, MdSummarize } from "react-icons/md";
import { MdChangeCircle } from "react-icons/md";
import { RiFileSettingsFill } from "react-icons/ri";
import { FaUsersGear } from "react-icons/fa6";
import { FaUserCog } from "react-icons/fa";
import { IoInformationCircle } from "react-icons/io5";
import { FaPowerOff } from "react-icons/fa";

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

const SettingsLeftPanel = ({ servicesManager, hotkeysManager, extensionManager, sortedStudies }) => {
  const navigate = useNavigate();

  const [appConfig] = useAppConfig();
  const location = useLocation();
  const { show, hide } = useModal();
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();
  const {
    userToken,
    token,
    setToken,
  } = useUser();

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const hasAdminAndDeputyAdminPermission =
    token?.realm_access?.roles.includes('super-admin') ||
    user?.profile?.roleType.includes('DeputyAdmin') ||
    token?.realm_access?.roles.includes('deputy-admin');

  const { t } = useTranslation();
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const versionNumber = process.env.VERSION_NUMBER;
  const commitHash = process.env.COMMIT_HASH;

  const menuOptions = [
    {
      title: t('Header:About'),
      // icon: 'info',
      icon: <IoInformationCircle />,
      onClick: () =>
        show({
          content: AboutModal,
          title: t('AboutModal:About OHIF Viewer'),
          contentProps: { versionNumber, commitHash },
        }),
    },
    {
      title: t('Header:Preferences'),
      // icon: 'settings',
      icon: <FaUserCog />,
      onClick: () =>
        show({
          title: t('UserPreferencesModal:User preferences'),
          content: UserPreferences,
          contentProps: {
            hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
            hotkeyDefinitions,
            currentLanguage: currentLanguage(),
            availableLanguages,
            defaultLanguage,
            onCancel: () => {
              hotkeys.stopRecord();
              hotkeys.unpause();
              hide();
            },
            onSubmit: ({ hotkeyDefinitions, language }) => {
              if (language.value !== currentLanguage().value) {
                i18n.changeLanguage(language.value);
              }
              hotkeysManager.setHotkeys(hotkeyDefinitions);
              hide();
            },
            onReset: () => hotkeysManager.restoreDefaultBindings(),
            hotkeysModule: hotkeys,
          },
        }),
    },
  ];

  if (appConfig.oidc) {
    menuOptions.push({
      title: t('Header:Logout'),
      // icon: 'power-off',
      icon: <FaPowerOff />,
      onClick: async () => {
        navigate(`/logout?redirect_uri=${encodeURIComponent(window.location.href)}`);
      },
    });
  }

  const menuItems = [
    {
      label: 'My Account',
      link: '/setting/my-account',
      icon: <MdAccountBalance />
    },
    {
      label: 'Display Setting',
      link: '/setting/display-setting',
      icon: <MdOutlineSettingsBrightness />
    },
    hasAdminAndDeputyAdminPermission && {
      label: 'Radiology Group Settings',
      link: '/setting/radiologist-group-setting',
      icon: <FaUsersGear />
    },
    hasAdminAndDeputyAdminPermission && {
      label: 'Change Radiologist Group',
      link: '/setting/change-radiologist',
      icon: <MdChangeCircle />
    },
    hasAdminAndDeputyAdminPermission && {
      label: 'Report Setting',
      link: '/setting/report-setting',
      icon: <RiFileSettingsFill />
    },
    hasAdminAndDeputyAdminPermission && {
      label: 'Report Summary',
      link: '/setting/report-summary',
      icon: <MdSummarize />
    },
  ].filter(Boolean);

  const handleMenuItemClick = (link, label) => {
    navigate(link);
  };

  return (
    <>
      <Header
        menuOptions={menuOptions}
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        sortedStudies={sortedStudies}
        hotkeysManager={hotkeysManager}
      />

      <section className="mr-5 flex w-56">
        <ul
          className="bg-primary-dark top-0 w-56 text-white"
          style={{
            height: 'calc(100vh - 53px)',
          }}
        >
          {menuItems.map(menuItem => (
            <li
              key={menuItem.label}
              className={classNames(
                'border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border p-2 transition duration-300 flex items-center gap-2 group'
              )}
              onClick={() => handleMenuItemClick(menuItem.link, menuItem.label)}
            >
              <span
                className='text-blue-400 bg-[#183153] p-1 text-2xl duration-300 group-hover:bg-blue-400 group-hover:text-white transition-all rounded-sm'
                style={{
                  boxShadow:
                    'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                }}
              >
                {menuItem.icon}
              </span>

              {menuItem.label}
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default SettingsLeftPanel;
