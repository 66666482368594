import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SettingsLeftPanel } from '@ohif/ui';
// Setting Components
import RadiologyTATSetting from './RadiologyTATSetting';
import RadiologyViewerSetting from './RadiologyViewerSetting';
import RadiologyPatientInformationSetting from './RadiologyPatientInformationSetting';
import RadiologyTimeZoneSetting from './RadiologyTimeZoneSetting';
import { useRadiologyGroup } from '../../contextProviders';

const RadiologyGroupSettings = ({
  onCancel,
  servicesManager,
  extensionManager,
  hotkeysManager,
  data: studies,
}) => {
  const {
    createRadiologyGroupSetting,
    radiologyGroupSetting,
    updateRadiologyGroupSetting,
    fetchRadiologyGroupSetting,
    setRadiologyGroupSetting,
    radiologyGroupList,
  } = useRadiologyGroup();

  const { t } = useTranslation('UserPreferencesModal');

  const TATSetting = radiologyGroupSetting?.find(item => item.name === 'RadiologyGroupTATSetting');
  const viewerSetting = radiologyGroupSetting?.find(
    item => item.name === 'RadiologyGroupViewerSetting'
  );
  const patientInformationSetting = radiologyGroupSetting?.find(
    item => item.name === 'RadiologyGroupPatientInformationSetting'
  );
  const timeZoneSetting = radiologyGroupSetting?.find(
    item => item.name === 'RadiologyGroupTimeZoneSetting'
  );

  const onCancelHandler = () => {
    onCancel();
  };

  return (
    <>
      <SettingsLeftPanel
        servicesManager={servicesManager}
        extensionManager={extensionManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
      />
      <div className="absolute top-16 left-60 text-white">
        {/* Radiology Group TAT Setting */}
        <RadiologyTATSetting
          createRadiologyGroupSetting={createRadiologyGroupSetting}
          updateRadiologyGroupSetting={updateRadiologyGroupSetting}
          TATSetting={TATSetting}
          fetchRadiologyGroupSetting={fetchRadiologyGroupSetting}
          setRadiologyGroupSetting={setRadiologyGroupSetting}
          onCancel={onCancel}
          radiologyGroupList={radiologyGroupList}
          studies={studies}
        />

        {/* Radiology Group Viewer Setting */}
        <RadiologyViewerSetting
          createRadiologyGroupSetting={createRadiologyGroupSetting}
          updateRadiologyGroupSetting={updateRadiologyGroupSetting}
          viewerSetting={viewerSetting}
          fetchRadiologyGroupSetting={fetchRadiologyGroupSetting}
          setRadiologyGroupSetting={setRadiologyGroupSetting}
          onCancel={onCancel}
          radiologyGroupList={radiologyGroupList}
        />

        {/* Radiology Group Patient Information Setting */}
        <RadiologyPatientInformationSetting
          createRadiologyGroupSetting={createRadiologyGroupSetting}
          updateRadiologyGroupSetting={updateRadiologyGroupSetting}
          patientInformationSetting={patientInformationSetting}
          fetchRadiologyGroupSetting={fetchRadiologyGroupSetting}
          setRadiologyGroupSetting={setRadiologyGroupSetting}
          onCancel={onCancel}
          radiologyGroupList={radiologyGroupList}
        />

        {/* Radiology Group Time zone Setting */}
        <RadiologyTimeZoneSetting
          createRadiologyGroupSetting={createRadiologyGroupSetting}
          updateRadiologyGroupSetting={updateRadiologyGroupSetting}
          timeZoneSetting={timeZoneSetting}
          fetchRadiologyGroupSetting={fetchRadiologyGroupSetting}
          setRadiologyGroupSetting={setRadiologyGroupSetting}
          onCancel={onCancel}
          radiologyGroupList={radiologyGroupList}
        />
      </div>
    </>
  );
};

const noop = () => {};

RadiologyGroupSettings.propTypes = {
  disabled: PropTypes.bool,
  hotkeyDefaults: PropTypes.object.isRequired,
  hotkeyDefinitions: PropTypes.object.isRequired,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  hotkeysModule: PropTypes.shape({
    initialize: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    unpause: PropTypes.func.isRequired,
    startRecording: PropTypes.func.isRequired,
    record: PropTypes.func.isRequired,
  }).isRequired,
};

RadiologyGroupSettings.defaultProps = {
  languageOptions: [
    { value: 'ONE', label: 'ONE' },
    { value: 'TWO', label: 'TWO' },
  ],
  onCancel: noop,
  onSubmit: noop,
  onReset: noop,
  disabled: false,
};

export default RadiologyGroupSettings;
