import React, { useEffect, useState } from 'react';
import { useAnalytics, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, AreaChart, Area } from 'recharts';
import InputDateRange from '../InputDateRange';
import Label from '../Label';
import moment from 'moment';

const Users = () => {
    const { studyCount, fetchReportsByUserAndModal, fetchUserWeeklyPerform, reportsByUserAndModal, userWeeklyPerform, setStartDate, setEndDate, startDate, endDate } = useAnalytics();
    const { show } = useSnackbar();
    const [username, setUsername] = useState(null);
    const [error, setError] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState<any>({ startDate, endDate });
    let totalRVUSum = 0;

    const fetchData = (startDate, endDate) => {
        Promise.all([
            fetchReportsByUserAndModal(startDate, endDate),
        ]).catch(error => setError(error));
    };

    const getLast7Days = () => {
        const today = new Date();
        const priorDate = new Date().setDate(today.getDate() - 7);

        return {
            startDate: new Date(priorDate).toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
        };
    };

    const handleDateRangeFieldChange = ({ startDate, endDate }) => {
        const start_date = moment(startDate).format('YYYY-MM-DD');
        const end_date = moment(endDate).format('YYYY-MM-DD');
        setSelectedDateRange({ start_date, end_date });
        setStartDate(start_date);
        setEndDate(end_date);
    };

    useEffect(() => {
        const { startDate, endDate } = getLast7Days();
        setSelectedDateRange({startDate, endDate})
        setStartDate(startDate);
        setEndDate(endDate);
        fetchData(startDate, endDate);
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchData(startDate, endDate);
            setSelectedDateRange({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (username && startDate && endDate) {
            fetchUserWeeklyPerform(username, startDate, endDate);
        }
    }, [username, startDate, endDate]);

    useEffect(() => {
        if (error) {
            show({ message: error.message, type: SnackbarTypes.ERROR });
        }
    }, [error, show]);

    const handleRowClick = (user) => {
        setUsername(user);
    };

    // Define types for your data
    interface ModalityCounts {
        [modality: string]: number;
    }

    interface UserModalities {
        [user: string]: ModalityCounts;
    }

    interface TransformedRow {
        [key: string]: number | string;
        total: number;
        totalRVU: number;
    }

    interface TransformResult {
        transformedData: TransformedRow[];
        allModalities: string[];
    }

    // Transform function for users
    const transformTableDataForUserAndModal = (data: UserModalities): TransformResult => {
        const allModalities = new Set<string>();

        const transformedData = Object.entries(data).map(([user, modalities]) => {
            const total = Object.values(modalities).reduce((sum, count) => sum + count, 0);

            const totalRVU =
                (modalities['CR/DX'] || 0) * 0.3 +
                (modalities['US'] || 0) * 0.7 +
                (modalities['CT'] || 0) * 1.5 +
                (modalities['CT\\PT'] || 0) * 1.5 +
                (modalities['DOC\\US'] || 0) * 1 +
                (modalities['MR'] || 0) * 1.4 +
                (modalities['MG'] || 0) * 0.7;
            totalRVUSum += totalRVU;

            Object.keys(modalities).forEach(modality => allModalities.add(modality));

            return {
                user,
                ...modalities,
                total,
                totalRVU: parseFloat(totalRVU.toFixed(2)), // Ensure totalRVU is a number, then round to 2 decimal places
            };
        });

        return { transformedData, allModalities: Array.from(allModalities) };
    };

    // Transform function for line chart data
    interface LineChartRawData {
        date: string;
        modality?: string;
        count: string;
    }

    const transformLineChartData = (rawData: LineChartRawData[]): { date: string; modality: string; count: number }[] => {
        return rawData.map(item => ({
            date: new Date(item.date).toLocaleDateString(), // Format date if needed
            modality: item.modality || 'Unknown', // Handle null or undefined modalities
            count: parseInt(item.count, 10), // Convert count to number
        }));
    };

    const { transformedData: userAndModalData, allModalities: userAndModalModalities } = reportsByUserAndModal
        ? transformTableDataForUserAndModal(reportsByUserAndModal)
        : { transformedData: [], allModalities: [] };

    const lineChartData = transformLineChartData(userWeeklyPerform);

    const CustomTooltip = ({ payload, label }) => {
        if (payload.length > 0) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Date: ${label}`}</p>
                    <p className="value">{`Value: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <div className=' flex items-center justify-between my-5 gap-5'>
                 <div className=' flex items-center gap-3'>
                    <Label className="mt-2" text={''}>Date Range : </Label>
                    <InputDateRange
                        id={'report-analutic'}
                        value={selectedDateRange}
                        onChange={handleDateRangeFieldChange}
                        setStudyFilterHeight={''}
                    />
               </div>
                <div style={{ textAlign: 'center' }}>
                    <p
                        className="inline-flex items-center rounded border border-[#3a3f99] bg-blue-400 py-2 px-4 font-semibold text-black transition-all opacity-80"
                    >
                        Study Count: {studyCount?.count || 0}
                    </p>
                </div>
            </div>

            <p className=' mb-5'><strong className=' text-[#5acce6]'>Total RVU :</strong> {totalRVUSum.toFixed(2)} [ MR = 1.4, CR/DX = 0.3, US = 0.7, MG = 0.7, CT = 1.5, CT\PT = 1.5, DOC\US = 1 ]</p>

            <div className={` mb-5 ${userAndModalData.length === 0 ? 'h-48 ' : ''}`} >
                <p className=' text-[#5acce6]'>Study by User and Modality</p>
                {userAndModalData.length > 0 ? (
                    <table className=" w-full bg-primary-dark  border-secondary-light border p-4 rounded-md  mt-2 table-auto text-white ">
                        <thead  className="bg-primary-dark border-secondary-light border">
                            <tr>
                                <th className=" p-2">User</th>
                                {userAndModalModalities.map(modality => (
                                    <th key={String(modality)} className=" p-2">{modality}</th>
                                ))}
                                <th className=" p-2">Total</th>
                                <th className=" p-2">Total RVU</th>
                            </tr>
                        </thead>
                        <tbody className="mt-1 ">
                            {userAndModalData.map((row, index) => (
                                <tr key={index}className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                                    <td
                                        className=" p-2 cursor-pointer"
                                        onClick={() => handleRowClick(row.user)}
                                    >
                                        {row.user}
                                    </td>
                                    {userAndModalModalities.map(modality => (
                                        <td key={String(modality)} className=" p-2">{row[modality] || 0}</td>
                                    ))}
                                    <td className=" p-2">{row.total}</td>
                                    <td className=" p-2">{row.totalRVU}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className=' h-full flex justify-center items-center bg-primary-dark  border-secondary-light border p-4 mt-2'>Data not available.</div>
                )}
            </div>
            {username && (
                <div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md'>
                    <p className=' text-[#5acce6]'>Date wise User performance</p>
                    <ResponsiveContainer width="100%" height={400}>
                        {lineChartData.length > 0 ? (
                            <AreaChart data={lineChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 '/>
                                <XAxis dataKey="date" stroke="#fff" />
                                <YAxis stroke="#fff" allowDecimals={false} domain={[0, 'auto']} />
                                <Tooltip content={CustomTooltip} />
                                <Line type="monotone" dataKey="count" stroke="#5acce6" />
                                <Area type="monotone" dataKey="count" stroke="#5acce6" fill="#FFBB28" />
                            </AreaChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    );
};

export default Users;
