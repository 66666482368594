import React from 'react';
import { Dashboard } from '@ohif/ui';

const Review = ({ servicesManager, extensionManager, hotkeysManager, data: studies  }) => {
  return (
    <section className="relative w-full text-white">
      <Dashboard
        servicesManager={servicesManager}
        extensionManager={extensionManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
      />
      <div className="absolute top-16 left-60">Review</div>
    </section>
  );
};

export default Review;
