import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useRadiologyGroup,
  useSnackbar,
  useUser,
} from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import axios from 'axios';
import { Button, SettingsLeftPanel, Typography, Select, Label } from '@ohif/ui';

const ChangeRadiologistMember = ({ servicesManager, extensionManager, hotkeysManager, data: studies, }) => {
  const { userAuthenticationService } = servicesManager.services;

  const user = userAuthenticationService.getUser();
  const { fetchUsers } = useUser();
  const {
    radiologyGroupList,
    deleteRadiologyGroupMember,
    addRadiologyGroupMember,
    fetchSingleRadiologyGroup,
    setSingleRadiologyGroupList,
    groupIds,
  } = useRadiologyGroup();

  const { t } = useTranslation('UserPreferencesModal');
  const [currentGroup, setCurrentGroup] = useState(null);
  const [cGroup, setCGroup] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [radiologistUserList, setRadiologistUserList] = useState([]);
  const { show } = useSnackbar();

  const [selectedUser, setSelectedUser] = useState(null);
  const optionGroup = radiologyGroupList.map(group => ({
    label: group.name,
    value: group.id,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${selectedUser}/groups`,
          {
            headers: {
              Authorization: `${user.token_type} ${user.access_token}`,
            },
          }
        );
        const data = res.data.map(data => data.name);
        const dataId = res.data.map(data => data.id);
        setCGroup(data.toString());
        setSelectedGroupName(dataId.toString());
        setCurrentGroup(res.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (selectedUser) {
      fetchData();
    }
  }, [selectedUser, user.token_type, user.access_token]);

  const handleSubmit = async () => {
    try {
      if (currentGroup && currentGroup.length > 0) {
        const findOldId = currentGroup.map(data => data.id);
        if (selectedGroupName && findOldId[0] !== selectedGroupName) {
          await addRadiologyGroupMember(selectedUser, selectedGroupName, user.access_token);
          await deleteRadiologyGroupMember(selectedUser, findOldId[0], user.access_token);
        }
        await fetchSingleRadiologyGroup(user.access_token, groupIds)
          .then(data => setSingleRadiologyGroupList(data))
          .catch(error => console.error('Error fetching users:', error));
      } else {
        await addRadiologyGroupMember(selectedUser, selectedGroupName, user.access_token);
        await fetchSingleRadiologyGroup(user.access_token, groupIds)
          .then(data => setSingleRadiologyGroupList(data))
          .catch(error => console.error('Error fetching users:', error));
      }
      show({
        title: 'Success!',
        message: 'Successfully Change Radiologist Group',
        type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
        position: 'topRight',
      });
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const handleDelete = async () => {
    await deleteRadiologyGroupMember(selectedUser, selectedGroupName, user.access_token);
    await fetchSingleRadiologyGroup(user.access_token, groupIds)
      .then(data => setSingleRadiologyGroupList(data))
      .catch(error => console.error('Error fetching users:', error));
    show({
      title: 'Success!',
      message: 'Successfully Remove Radiologist Group',
      type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
      position: 'topRight',
    });
  };

  useEffect(() => {
    fetchUsers(user.access_token)
      .then(data => setRadiologistUserList(data))
      .catch(error => console.error('Error fetching users:', error));
  }, [user.access_token]);

  const radiologistUser = radiologistUserList?.filter(
    user =>
      user.attributes &&
      user.attributes.roleType &&
      user.attributes.roleType.includes('Radiologist')
  );

  const optionRadiologistUser = radiologistUser?.map(data => ({
    label: data.username,
    value: data.id,
  }));

  const handleSelectionGroupNameChange = selected => {
    setSelectedGroupName(selected.value);
  };

  const handleSelectionRadiologistUser = selected => {
    setSelectedUser(selected.value);
  };

  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-black">
        <Typography
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2]"
        >
          {title}
        </Typography>
      </div>
      <div className="mt-4 mb-8">{children}</div>
    </>
  );

  return (
    <>
      <section className="relative w-full text-white">
        <SettingsLeftPanel
          servicesManager={servicesManager}
          extensionManager={extensionManager}
          hotkeysManager={hotkeysManager}
          sortedStudies={studies}
        />
        <div className="absolute top-16 left-60">
          {' '}
          <Section title={t('Change Radiologist Group')}>
            <div className="flex items-end">
              <div className="grid grid-cols-3 gap-4">
                <div className="w-72">
                  <Label className="my-2 block">Radiology User</Label>
                  <Select
                    id="group"
                    className="mr-5"
                    placeholder={'Select Radiology User'}
                    onChange={handleSelectionRadiologistUser}
                    options={optionRadiologistUser}
                    value={[selectedUser]}
                  />
                </div>
                <div className="w-72">
                  <Label className="my-2 block">Radiology Group Name </Label>
                  <Select
                    id="group"
                    className="mr-5"
                    placeholder={'Select Radiology Name'}
                    onChange={handleSelectionGroupNameChange}
                    options={optionGroup}
                    value={[selectedGroupName]}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-end">
              <div className="grid grid-cols-2 gap-4">
                <div className="mt-4">
                  <Button onClick={handleSubmit}>Update Radiologist Group </Button>
                </div>
                <div
                  className="mt-4"
                  style={{ marginRight: '-27px' }}
                >
                  <Button onClick={handleDelete}>Remove Radiologist Group</Button>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </section>
    </>
  );
};

export default ChangeRadiologistMember;
