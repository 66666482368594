import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { fetchDefaultTemplates, createPatientReports, fetchPatientReports, generateReportPdf, updatePatientReports, generateReportWord, deletePatientReport, fetchReportSetting, createReportSetting, updateReportSetting, deleteReportSetting, createDefaultTemplates, deleteDefaultTemplates, updateDefaultTemplates} from '../requestHandler';

export const ReportEditorContext = createContext(null);

export const ReportEditorProvider = ({ children, service, extension }) => {
  const [defaultTemplates, setDefaultTemplates] = useState('');
  const [patientReportsDetails, setPatientReportsDetails] = useState('');
  const [reportSettingDetails, setReportSettingDetails] = useState('');
  const [reportEditorTemplate, setReportEditorTemplate] = useState('<p>Hello</p><p>World</p>');
  const [toggleDisplayReportEditor, setToggleDisplayReportEditor] = useState(false);

  const activeDataSource = extension.activeDataSource;
  const dataSource = extension.dataSourceMap[activeDataSource][0];

  useEffect(() => {
    fetchDefaultTemplates()
      .then(data => setDefaultTemplates(data))
      .catch(error => console.error('Error fetching default templates:', error));

    fetchPatientReports()
      .then(data => setPatientReportsDetails(data))
      .catch(error => console.error('Error fetching patient details:', error));

    fetchReportSetting()
      .then(data => setReportSettingDetails(data))
      .catch(error => console.error('Error fetching report setting details:', error));
  }, []);

  const getCurrentPatient = useCallback(async (params, query, studyInstanceUid) => {
    if (params.pathname.includes('report-editor')) {
      dataSource.initialize({ params, query });
    }

    return await dataSource.query.studies.search({ studyInstanceUid });
  }, []);


  const value = useMemo(
    () => ({
      defaultTemplates,
      setDefaultTemplates,
      reportEditorTemplate,
      setReportEditorTemplate,
      toggleDisplayReportEditor,
      setToggleDisplayReportEditor,
      getCurrentPatient,
      dataSource,
      createPatientReports,
      patientReportsDetails,
      generateReportPdf,
      updatePatientReports,
      setPatientReportsDetails,
      generateReportWord,
      deletePatientReport,
      reportSettingDetails,
      setReportSettingDetails,
      createReportSetting,
      updateReportSetting,
      deleteReportSetting,
      createDefaultTemplates,
      fetchDefaultTemplates,
      deleteDefaultTemplates,
      updateDefaultTemplates,
    }),
    [reportEditorTemplate, toggleDisplayReportEditor, defaultTemplates, patientReportsDetails, reportSettingDetails]
  );

  return <ReportEditorContext.Provider value={value}>{children}</ReportEditorContext.Provider>;
};

export const useReportEditor = () => useContext(ReportEditorContext);
