import React, { useState } from 'react';
import { Select, SettingsLeftPanel, Typography } from '@ohif/ui';

const DisplaySetting = ({
  servicesManager,
  extensionManager,
  hotkeysManager,
  theme,
  fontSize,
  tat,
  alert,
  timeZone,
  daylightSaving,
  data: studies,
}) => {
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const [state, setState] = useState({
    theme,
    fontSize,
    tat,
    alert,
    timeZone,
    daylightSaving,
  });

  const themesOptions = [
    { value: 'light', label: 'Light' },
    { value: 'dark', label: 'Dark' },
  ];

  const fontSizesOptions = [
    { value: 'large', label: 'Large' },
    { value: 'medium', label: 'Medium' },
    { value: 'small', label: 'Small' },
  ];
  const dayLightOptions = [
    { value: 'on', label: 'On' },
    { value: 'off', label: 'Off' },
  ];

  const onValueChange = (name, value) => {
    setState(state => ({ ...state, [name]: value }));
  };

  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-black">
        <Typography
          component={'p'}
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2]"
        >
          {title}
        </Typography>
      </div>
      <div className="mt-4 mb-8">{children}</div>
    </>
  );
  return (
    <section className="relative w-full text-white">
      <SettingsLeftPanel
        servicesManager={servicesManager}
        extensionManager={extensionManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
      />
      <div className="absolute top-16 left-60">
        {' '}
        <Section title={'Display settings'}>
          <div className="mt-2 flex justify-between">
            <div className="flex w-96 items-center justify-between">
              <div className="w-auto">
                <Typography
                  variant="subtitle"
                  className="mr-5 h-full"
                  component={'p'}
                >
                  Theme
                </Typography>
              </div>
              <div className="w-48">
                <Select
                  id={'theme'}
                  isClearable={false}
                  onChange={value => onValueChange('theme', value)}
                  options={themesOptions}
                  value={state.theme}
                />
              </div>
            </div>

            <div className="flex w-96 items-center justify-between">
              <div className="w-auto">
                <Typography
                  component={'p'}
                  variant="subtitle"
                  className="mr-5 h-full"
                >
                  {'Font Size'}
                </Typography>
              </div>
              <div className="w-48">
                <Select
                  id="font-size"
                  isClearable={false}
                  onChange={value => onValueChange('fontSize', value)}
                  options={fontSizesOptions}
                  value={state.fontSize}
                />
              </div>
            </div>
          </div>

          <div className="mt-2 flex justify-between">
            <div className=" flex w-96 items-center justify-between">
              <div className="w-auto">
                <Typography
                  component={'p'}
                  variant="subtitle"
                  className="mr-5 h-full"
                >
                  TAT
                </Typography>
              </div>
              <div className="w-48">
                <Select
                  id="tat"
                  isClearable={false}
                  onChange={value => onValueChange('theme', value)}
                  options={themesOptions}
                  value={state.theme}
                />
              </div>
            </div>
            <div className=" flex w-96 items-center justify-between">
              <div className="w-auto">
                <Typography
                  component={'p'}
                  variant="subtitle"
                  className="mr-5 h-full"
                >
                  Email/WhatsApp alert
                </Typography>
              </div>
              <div className="w-48">
                <label className="inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    value={state.alert}
                    onClick={() =>
                      setState(prevState => ({ ...prevState, alert: !prevState.alert }))
                    }
                    className="peer sr-only"
                  />
                  <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                </label>
              </div>
            </div>
          </div>

          <div className="mt-2 flex justify-between">
            <div className=" flex w-96 items-center justify-between">
              <div className="w-auto">
                <Typography
                  component={'p'}
                  variant="subtitle"
                  className="mr-5 h-full"
                >
                  {'Time Zone'}
                </Typography>
              </div>
              <div className="w-48">
                <Select
                  id="timezone"
                  isClearable={false}
                  onChange={value => onValueChange('theme', value)}
                  options={themesOptions}
                  value={state.timeZone}
                />
              </div>
            </div>

            <div className=" flex w-96 items-center justify-between">
              <div className="w-auto">
                <Typography
                  component={'p'}
                  variant="subtitle"
                  className="mr-5 h-full"
                >
                  {'Daylight Saving'}
                </Typography>
              </div>
              <div className="w-48">
                <label className="inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    value={state.daylightSaving}
                    onClick={() =>
                      setState(prevState => ({ ...prevState, daylightSaving: !prevState.alert }))
                    }
                    className="peer sr-only"
                  />
                  <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                </label>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </section>
  );
};

export default DisplaySetting;
