export const fetchDocumentUpload = () => {
    return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/document-upload`)
        .then((response) => response.json())
}

export const createDocument = async (studyInstanceUid, attachmentData, setDocumentUploadDetails) => {
    const formData = new FormData();
    formData.append('study_UIDs', studyInstanceUid);
    formData.append('attachment', attachmentData);

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/document-upload`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Failed to create document: ${response.statusText}`);
        }

        const data = await response.json();
        await fetchDocumentUpload()
            .then((data) => setDocumentUploadDetails(data))
            .catch((error) =>
                console.error('Error fetching document upload details:', error)
            );
        return data;
    } catch (error) {
        console.error('Error creating document:', error);
        throw error;
    }
};


export const updateDocument = async (id, attachmentData, setDocumentUploadDetails) => {
    const formData = new FormData();
    formData.append('attachment', attachmentData);

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/document-upload/${id}`, {
            method: 'PUT',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Failed to create document: ${response.statusText}`);
        }

        const data = await response.json();
        await fetchDocumentUpload()
            .then((data) => setDocumentUploadDetails(data))
            .catch((error) =>
                console.error('Error fetching document upload details:', error)
            );
        return data;
    } catch (error) {
        console.error('Error creating document:', error);
        throw error;
    }
};

export const deleteDocumentUrl = async (id, updateData, setDocumentUploadDetails) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/document-upload-url/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateData),
        })
        if (!response.ok) {
            throw new Error(`Failed to create document: ${response.statusText}`);
        }

        const data = await response.json();
        await fetchDocumentUpload()
            .then((data) => setDocumentUploadDetails(data))
            .catch((error) =>
                console.error('Error fetching document upload details:', error)
            );
        return data;
    } catch (error) {
        console.error('Error creating document:', error);
        throw error;
    }
};

export const sendReportData = async (shareDetails) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/send-mail`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(shareDetails),
    })
    if (!response.ok) {
        throw new Error(`Failed to create document: ${response.statusText}`);
    }

    const data = await response.json();
    // console.log(data)
    return data;
}

export const sendReportDataToWhastapp = async (shareDetails) => {
    console.log(shareDetails)
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/send-whatsapp`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(shareDetails),
    })
    if (!response.ok) {
        throw new Error(`Failed to create document: ${response.statusText}`);
    }

    const data = await response.json();
    // console.log(data)
    return data;
}