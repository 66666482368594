import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import Button from '../Button';

import { useUser, Dashboard, InputDateRange, LegacyButton, Icon } from '@ohif/ui';
import moment from 'moment';
import { GoDotFill } from 'react-icons/go';

const UsersStatus = ({ servicesManager, extensionManager, hotkeysManager, data: studies  }) => {
  const { usersList, fetchSessionsRadiologistUser } = useUser();
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const [session, setSession] = useState([]);
  const [radiologistUserLists, setRadiologistUserLists] = useState(usersList);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [isFiltering, setIsFiltering] = useState(false);

  const tableHeaders = {
    liveStatus: 'Live Status',
    firstName: 'FirstName',
    lastName: 'LastName',
    mobileNumber: 'Mobile Number',
    email: 'Email',
    radiologyGroup: 'Radiology Group',
    lastActiveTime: 'Last Active Time',
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  const radiologistUser = radiologistUserLists && radiologistUserLists?.map(data => data.id);

  useEffect(() => {
    fetchSessionsRadiologistUser(user.access_token, radiologistUser)
      .then(data => setSession(data))
      .catch(error => console.error('Error fetching radiology user session:', error));
  }, [user.accessTokens, usersList]);

  const handleDateRangeFieldChange = ({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const handleClearFilters = () => {
    setSelectedDateRange({ startDate: null, endDate: null });
    setRadiologistUserLists(usersList);
    setIsFiltering(false);
  };

  const handleSearch = () => {
    const startTimestamp = moment(selectedDateRange.startDate).format('MM/DD/YYYY');
    const endTimestamp = moment(selectedDateRange.endDate).format('MM/DD/YYYY');
    const filteredUsers = radiologistUserLists.filter(user => {
      const userTimestamp = moment(user.createdTimestamp).format('MM/DD/YYYY');

      return userTimestamp >= startTimestamp && userTimestamp <= endTimestamp;
    });
    setIsFiltering(true);
    setRadiologistUserLists(filteredUsers);
  };

  return (
    <section
      className="relative w-full text-white"
      style={{}}
    >
      <Dashboard
        servicesManager={servicesManager}
        extensionManager={extensionManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
      />
      <div className="absolute top-16 left-60 w-9/12">
        <div className="container relative mx-auto flex w-full flex-col pt-5">
          <div className="mb-5 flex flex-row justify-between">
            <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
              <Typography
                variant="h6"
                className="text-white"
                component={'h6'}
              >
                Users Status
              </Typography>
            </div>
            <div className="flex h-[34px] flex-row items-center">
              {isFiltering && (
                <LegacyButton
                  rounded="full"
                  variant="outlined"
                  color="primaryActive"
                  border="primaryActive"
                  className="mx-8"
                  startIcon={<Icon name="cancel" />}
                  onClick={handleClearFilters}
                >
                  ClearFilters
                </LegacyButton>
              )}
              <InputDateRange
                value={selectedDateRange}
                onChange={handleDateRangeFieldChange}
              />
              <Button
                className="ml-5 mt-2.5 py-0.5 px-8"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
          <table className="container m-auto mt-2 w-full table-auto text-white">
            <thead className="bg-primary-dark border-secondary-light border">
              <tr>
                {values.map(tableHead => (
                  <th
                    className="p-2"
                    key={tableHead}
                  >
                    {tableHead}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="mt-1 ">
              {radiologistUserLists &&
                radiologistUserLists.map(instance => {
                  const sessionData = session.find(sessionData =>
                    sessionData.some(data => data.userId === instance.id)
                  );
                  const lastActiveDate = sessionData?.find(data => data.userId === instance.id)?.lastAccess;

                  return (
                    <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                        {keys.map(key => (
                          <td
                            className="p-2"
                            key={key}
                          >
                            {key === 'liveStatus' ? (
                              <span>
                                <GoDotFill
                                  fontSize={37}
                                  style={{ color: sessionData  ? 'lime' : 'red' }}
                                  className="ml-4"
                                />
                              </span>
                            ) : key === 'qualification' ? (
                              <span className="cursor-pointer">
                                {instance?.attributes?.qualification}
                              </span>
                            ) : key === 'title' ? (
                              <span className="cursor-pointer">{instance?.attributes?.title}</span>
                            )  : key === 'registration' ? (
                              <span className="cursor-pointer">
                                {instance?.attributes?.registrationNo}
                              </span>
                            )  : key === 'radiologyGroup' ? (
                              <span className="cursor-pointer">
                                {instance?.attributes?.radiologyGroup}
                              </span>
                            ) : key === 'mobileNumber' ? (
                              <span className="cursor-pointer">
                                {instance?.attributes?.mobileNumber}
                              </span>
                            ) : key === 'lastActiveTime' ? (
                              <span className="cursor-pointer">
                              {lastActiveDate ? moment(lastActiveDate).format('MMM-DD-YYYY hh:mm:ss A') : 'N/A'}
                            </span>
                            ) : (
                              instance[key]
                            )}
                          </td>
                        ))}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default UsersStatus;
