export const fetchDefaultTemplates = () => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/templates`)
    .then((response) => response.json())
}

export const createDefaultTemplates = async (templatesDetail) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/templates`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(templatesDetail),
  })
  return response
}

export const updateDefaultTemplates = async (id, data) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/templates/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return response
};

export const deleteDefaultTemplates = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/templates/${id}`, {
    method: 'DELETE',
  })

  return response;
}

export const fetchPatientReports = () => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports`)
    .then((response) => response.json())
}

export const createPatientReports = async (patientDetail, setPatientReportsDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientDetail),
  })
  await fetchPatientReports()
    .then((data) => setPatientReportsDetails(data))
    .catch((error) =>
      console.error('Error fetching patient details:', error)
    );
  return response
};

export const updatePatientReports = async (id,patientDetail,setPatientReportsDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientDetail),
  })
  await fetchPatientReports()
    .then((data) => setPatientReportsDetails(data))
    .catch((error) =>
      console.error('Error fetching patient details:', error)
    );
  return response
};

export const deletePatientReport = async (id, setPatientReportsDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports/${id}`, {
    method: 'DELETE',
  })
  await fetchPatientReports()
    .then(data => setPatientReportsDetails(data))
    .catch(error => console.error('Error fetching patient details:', error));

  return response;
}

export const generateReportPdf = (reportdetails, setIsLoading, patientName, notDownload) => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/download-pdf`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ html: reportdetails })
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${patientName}.pdf`;
      !notDownload ? document.body.appendChild(a) : null;
      !notDownload ? a.click() : null;
      notDownload ? window.open(url, '_blank') : null;
      window.URL.revokeObjectURL(url);
    })
    .catch(error => console.error('Error:', error))
    .finally(() => {
      setIsLoading(false);
    });
};

export const generateReportWord = (reportdetails, patientName) => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/download-doc`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ html: reportdetails })
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${patientName}.docx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => console.error('Error:', error))
    .finally(() => {
    });
};


// report setting 
export const fetchReportSetting = () => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports-setting`)
    .then((response) => response.json())
}

export const createReportSetting = async (reportSettingDetails, setReportSettingDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports-setting`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reportSettingDetails),
  })
  await fetchReportSetting()
    .then((data) => setReportSettingDetails(data))
    .catch((error) =>
      console.error('Error fetching report setting details:', error)
    );
  return response
};

export const updateReportSetting = async (id, reportSettingDetails, setReportSettingDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports-setting/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reportSettingDetails),
  })
  await fetchReportSetting()
    .then((data) => setReportSettingDetails(data))
    .catch((error) =>
      console.error('Error fetching report setting details:', error)
    );
  return response
};

export const deleteReportSetting = async (id, setReportSettingDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports-setting/${id}`, {
    method: 'DELETE',
  })
  await fetchReportSetting()
    .then((data) => setReportSettingDetails(data))
    .catch((error) =>
      console.error('Error fetching report setting details:', error)
    );
  return response
};