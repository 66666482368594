import React from 'react';
import { Dashboard } from '@ohif/ui';

const MonitorStudies = ({ servicesManager, extensionManager, hotkeysManager }) => {
  return (
    <section className="relative w-full text-white">
      <Dashboard
        servicesManager={servicesManager}
        extensionManager={extensionManager}
        hotkeysManager={hotkeysManager}
      />
      <div className="absolute top-16 left-60">Monitor Studies</div>
    </section>
  );
};

export default MonitorStudies;