import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import moment from 'moment';
import Typography from '../Typography';
import { useModal, useRadiologyGroup, useUser } from '../../contextProviders';
import { Header } from '@ohif/ui';
import { useAppConfig } from '@state';

import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import PhysicianCreateModel from './PhysicianCreateModel';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";

const Physician = ({ servicesManager, hotkeysManager, extensionManager }) => {
  const { usersList, createUser, updateUser, fetchUsers, setUsersList, deleteUser } = useUser();

  const { radiologyGroupList, addRadiologyGroupMember, deleteRadiologyGroupMember } =
    useRadiologyGroup();

  const tableHeaders = {
    firstName: 'FirstName',
    lastName: 'LastName',
    username: 'Username',
    email: 'Email',
    mobileNumber: 'Mobile Number',
    radiologyGroup: 'Radiology Group',
    createdTimestamp: 'CreatedAt',
    update: 'Update',
    delete: 'Delete',
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);
  const { userAuthenticationService } = servicesManager.services;
  const { show, hide } = useModal();
  const user = userAuthenticationService.getUser();

  const handleDelete = async id => {
    Swal.fire({
      // title: 'Are you sure?',
      text: 'Are you sure to delete this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        // If the user confirms deletion, proceed with the deletion
        await deleteUser(id, user.access_token)
          .then(async res => {
            Swal.fire({
              title: 'Deleted',
              text: 'User has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });

            if (res.status === 204) {
              await fetchUsers(user.access_token)
                .then(data => setUsersList(data))
                .catch(error => console.error('Error fetching users:', error));
            }
          })
          .catch(error => {
            console.error('Error deleting user:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete user. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          // title: 'Cancelled',
          text: 'User Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK',
        });
      }
    });
  };

  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const modelShow = physicianData => {
    show({
      content: PhysicianCreateModel,
      title: `Physician: ${physicianData.username ? 'Update Physician User' : 'Create New Physician User'} `,
      contentProps: {
        user,
        hide,
        editPhysicianUserData: physicianData.username ? physicianData : '',
        createUser,
        updateUser,
        fetchUsers,
        setUsersList,
        radiologyGroupList,
        addRadiologyGroupMember,
        deleteRadiologyGroupMember,
      },
    });
  };

  const physicianUserLists =
    usersList &&
    usersList?.filter(
      user =>
        user.attributes &&
        user.attributes.roleType &&
        user.attributes.roleType.includes('Physician')
    );

  return (
    <section className="">
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        hotkeysManager={hotkeysManager}
      />
      <ToastContainer />
      <div className="container relative mx-auto flex flex-col pt-5">
        <div className="mb-5 flex flex-row justify-between">
          <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
            <Typography
              variant="h6"
              className="text-white"
              component={'h6'}
            >
              Physician User
            </Typography>
          </div>
          <div className="flex h-[34px] flex-row items-center">
            <Typography
              variant="h6"
              className="text-primary-light cursor-pointer"
              component={'h6'}
              onClick={modelShow}
            >
              Add Physician User
            </Typography>
          </div>
        </div>
      </div>
      <table className="container m-auto mt-2 table-auto text-white">
        <thead className="bg-primary-dark border-secondary-light border">
          <tr>
            {values.map(tableHead => (
              <th
                className="p-2"
                key={'tableHead'}
              >
                {tableHead}
              </th>
            ))}
          </tr>
        </thead>
        {physicianUserLists && physicianUserLists.length <= 0 ? (
          <span className="absolute  flex h-96 w-5/12 items-center justify-end">
            No Physician Users Exiting
          </span>
        ) : (
          <tbody className="mt-1 ">
            {physicianUserLists &&
              physicianUserLists?.map(instance => (
                <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                  {keys.map(key => (
                    <td
                      className="p-2"
                      key={key}
                    >
                      {key === 'delete' ? (
                        <div
                          className="cursor-pointer transition-all hover:text-red-400 text-xl text-[#ff0000] flex justify-center items-center"
                          onClick={() => handleDelete(instance.id)}
                        >
                           <RiDeleteBin6Fill />
                        </div>
                      ) : key === 'update' ? (
                        <div
                          className="cursor-pointer transition-all hover:text-[#63b3ed] text-xl flex justify-center items-center"
                          onClick={() => modelShow(instance)}
                        >
                          <FaEdit />
                        </div>
                      ) : key === 'radiologyGroup' ? (
                        <span className="cursor-pointer">
                          {instance?.attributes?.radiologyGroup}
                        </span>
                      ) : key === 'mobileNumber' ? (
                        <span
                          className="cursor-pointer"
                          onClick={() => modelShow(instance)}
                        >
                          {instance?.attributes?.mobileNumber}
                        </span>
                      ) : key === 'createdTimestamp' ? (
                        <span className="cursor-pointer">
                          {moment(instance.createdTimestamp).format('MM/DD/YYYY hh:mm:ss A')}
                        </span>
                      ) : (
                        instance[key]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </section>
  );
};

export default Physician;
