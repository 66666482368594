import React, { useState } from 'react';
import { Dashboard, InputDateRange, Typography } from '@ohif/ui';
import moment from 'moment';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

const UploadStatus = ({ servicesManager, extensionManager, hotkeysManager, data: studies, }) => {
  const { t } = useTranslation('UserPreferencesModal');

  const tableHeaders = {
    dlagCenter: 'Dlag Center',
    seriesInDlag: 'Series in Dlag',
    seriesInServer: 'Series in Server',
    studyInDlag: ' Study in Dlag',
    studyInServer: 'Study in Server',
    SeriesInServerWithoutMismatch: 'Series in Server Without Mismatch',
    seriesFeiled: 'Server Feiled',
    studyFailed: 'Study Failed',
    seriesInstanceMismatch: 'Series - Instance Mismatch'
  }

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });

  const handleDateRangeFieldChange = ({ startDate, endDate }) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const handleSearch = () => {
    const startTimestamp = moment(selectedDateRange.startDate).format('MM/DD/YYYY');
    const endTimestamp = moment(selectedDateRange.endDate).format('MM/DD/YYYY');
    // const filteredUsers = radiologistUserList.filter(user => {
    //   const userTimestamp = moment(user.createdTimestamp).format('MM/DD/YYYY');

    //   return userTimestamp >= startTimestamp && userTimestamp <= endTimestamp;
    // });
    // setIsFiltering(true);
    // setRadiologistUserLists(filteredUsers);
  };

  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-black">
        <Typography
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2]"
        >
          {title}
        </Typography>
      </div>
      <div className="mt-4 mb-8">{children}</div>
    </>
  );

  return (
    <section className="relative w-full text-white">
      <Dashboard
        servicesManager={servicesManager}
        extensionManager={extensionManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
      />
      <div className="absolute top-16 left-60">
        <Section title={t('Upload Status')}>

          <div className="flex h-[34px] flex-row items-center mb-5">
            <p className=' pr-2 pt-1'>Select Date :- {" "}</p>
            <InputDateRange
              value={selectedDateRange}
              onChange={handleDateRangeFieldChange}
            />
            <Button
              className="ml-5 mt-2.5 py-0.5 px-8"
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
          <table className="container m-auto mt-2 table-auto text-white">
            <thead className="bg-primary-dark border-secondary-light border">
              <tr>
                {values.map(tableHead => (
                  <th className="p-2" key={'tableHead'}>
                    {tableHead}
                  </th>
                ))}

              </tr>
            </thead>
            <tbody className="mt-1 ">

              <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                {keys.map(key => (
                  <td className="p-2" >
                    {key}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </Section>
      </div>
    </section>
  );
};

export default UploadStatus;
