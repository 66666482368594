import React, { useState } from 'react';
import Typography from '../Typography';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { useAppConfig } from '@state';
import { Header } from '@ohif/ui';
import { SiStatuspal } from "react-icons/si";
import { BiSolidUserCheck, BiSolidReport, BiSolidMessageCheck } from "react-icons/bi";
import { FaChartLine, FaUpload } from "react-icons/fa";
import { MdRateReview } from "react-icons/md";
import { AiOutlineAudit } from "react-icons/ai";
import { FaFileInvoiceDollar, FaShareFromSquare } from 'react-icons/fa6';
import { HiDocumentDuplicate } from "react-icons/hi2";
import { PiUserListFill } from "react-icons/pi";


const Dashboard = ({ servicesManager, hotkeysManager, extensionManager, sortedStudies }) => {
  const navigate = useNavigate();

  const [appConfig] = useAppConfig();
  const location = useLocation();
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const menuItems = [
    {
      label: 'Connectivity Status',
      link: '/dashboard/connectivity-status',
      icon: <SiStatuspal />
    },
    {
      label: 'Users Status',
      link: '/dashboard/users-status',
      icon: <BiSolidUserCheck />
    },
    {
      label: 'Upload Status',
      link: '/dashboard/upload-status',
      icon: <FaUpload />
    },
    {
      label: 'Analytics',
      link: '/dashboard/analytics',
      icon: <FaChartLine />
    },
    {
      label: 'Message Status',
      link: '/dashboard/message-status',
      icon: <BiSolidMessageCheck />
    },
    {
      label: 'Audit Logs',
      link: '/dashboard/audit-logs',
      icon: <AiOutlineAudit />
    },
    {
      label: 'Report Template',
      link: '/dashboard/report-template',
      icon: <HiDocumentDuplicate />
    },
    {
      label: 'Radiologist Status',
      link: '/dashboard/radiologist-status',
      icon: <PiUserListFill />
    },
    {
      label: 'Review',
      link: '/dashboard/review',
      icon: <MdRateReview />
    },
    {
      label: 'Share Report',
      link: '/dashboard/share-report',
      icon: <FaShareFromSquare />
    },
    {
      label: 'Invoice',
      link: '/dashboard/invoice',
      icon: <FaFileInvoiceDollar />
    },
  ];

  const handleMenuItemClick = (link, label) => {
    navigate(link);
  };

  return (
    <>
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        sortedStudies={sortedStudies}
        hotkeysManager={hotkeysManager}
      />

      <section className="mr-5 flex w-56">
        <ul
          className="bg-primary-dark top-0 w-56 text-white"
          style={{
            height: 'calc(100vh - 53px)',
          }}
        >
          {menuItems.map(menuItem => (
            <li
              key={menuItem.label}
              className={classNames(
                'border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border p-2 transition duration-300 flex items-center gap-2 group'
              )}
              onClick={() => handleMenuItemClick(menuItem.link, menuItem.label)}
            >
              <span
                className='text-blue-400 bg-[#183153] text-2xl p-1 duration-300 group-hover:bg-blue-400 group-hover:text-white transition-all rounded-sm'
                style={{
                  boxShadow:
                    'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                }}
              >
                {menuItem.icon}
              </span>
              {menuItem.label}
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Dashboard;
