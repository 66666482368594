
const studyAndModality = [
    {
        studyDescription: 'DUPLEX LOWER EXTREMITY VEINS',
        modality: 'US'
    },
    {
        studyDescription: "DUPLEX UPPER EXTREMITY VEINS",
        modality: 'US'
    },
    {
        studyDescription: "DUPLEX LOWER EXTREMITY ARTERIES",
        modality: 'US'
    },
    {
        studyDescription: "UPPER EXTREMITY ARTERIAL DUPLEX AND DOPPLER ",
        modality: 'US'
    },
    {
        studyDescription: "BILATERAL LOWER EXTREMITY ARTERIAL DUPLEX AND DOPPLER",
        modality: 'US'
    },
    {
        studyDescription: "LOWER EXTREMITY ARTERIAL DUPLEX AND DOPPLER",
        modality: 'US'
    },
    {
        studyDescription: "DUPLEX BILATERAL EXTRACRANIAL ARTERIES",
        modality: 'US'
    },
    {
        studyDescription: "Duplex Bilateral Lower Extremity Veins",
        modality: 'US'
    },
    {
        studyDescription: "Bilateral Lower Extremity Arterial ",
        modality: 'US'
    },
    {
        studyDescription: "Abdomen Complete",
        modality: 'US'
    },
    {
        studyDescription: "Abdomen Limited Upper Right Quadrant Gallbladder",
        modality: 'US'
    },
    {
        studyDescription: "First Trimester Pregnancy",
        modality: 'US'
    },
    {
        studyDescription: "Third Trimester Obstetric",
        modality: 'US'
    },
    {
        studyDescription: "Second Trimester Obstetric",
        modality: 'US'
    },
    {
        studyDescription: "Pelvis",
        modality: 'US'
    },
    {
        studyDescription: "Transabdominal",
        modality: 'US'
    },
    {
        studyDescription: "Second Trimester Pregnancy Limited",
        modality: 'US'
    },
    {
        studyDescription: "Chest",
        modality: 'US'
    },
    {
        studyDescription: "RETROPEITONEUM/RENAL",
        modality: 'US'
    },
    {
        studyDescription: "BREAST",
        modality: 'US'
    },
    {
        studyDescription: "BREAST",
        modality: 'MG'
    },
    {
        studyDescription: "SOFT TISSUE POSTERIOR HEAD",
        modality: 'US'
    },
    {
        studyDescription: "SOFT TISSUE HEAD",
        modality: 'US'
    },
    {
        studyDescription: "NECK SOFT TISSUES",
        modality: 'US'
    },
    {
        studyDescription: "Left thigh and spine area",
        modality: 'US'
    },
    {
        studyDescription: "Thyroid",
        modality: 'US'
    },
    {
        studyDescription: "Renal",
        modality: 'US'
    },
    {
        studyDescription: "RIGHT SOFT TISSUE MID BACK",
        modality: 'US'
    },
    {
        studyDescription: "Scrotum",
        modality: 'US'
    },
    // CR / DX
    {
        studyDescription: "Ankle",
        modality: 'CR'
    },
    {
        studyDescription: "Bone",
        modality: 'CR'
    },
    {
        studyDescription: "Both Hips",
        modality: 'CR'
    },
    {
        studyDescription: "Whole Spine",
        modality: 'CR'
    },
    {
        studyDescription: "Thoracic Spine",
        modality: 'CR'
    },
    {
        studyDescription: "Cervical Spine",
        modality: 'CR'
    },
    {
        studyDescription: "Lumbosacral Spine",
        modality: 'CR'
    },
    {
        studyDescription: "Chest",
        modality: 'CR'
    },
    {
        studyDescription: "Elbow",
        modality: 'CR'
    },
    {
        studyDescription: "Facial Bones",
        modality: 'CR'
    },
    {
        studyDescription: "Femur",
        modality: 'CR'
    },
    {
        studyDescription: "Foot",
        modality: 'CR'
    },
    {
        studyDescription: "Knee",
        modality: 'CR'
    },
    {
        studyDescription: "Ribs",
        modality: 'CR'
    },
    {
        studyDescription: "Hand",
        modality: 'CR'
    },
    {
        studyDescription: "Shoulder",
        modality: 'CR'
    },
    {
        studyDescription: "Skull",
        modality: 'CR'
    },
    {
        studyDescription: "Tibia-Fibula",
        modality: 'CR'
    },
    {
        studyDescription: "Wrist",
        modality: 'CR'
    },
    {
        studyDescription: "Finger",
        modality: 'CR'
    },
    {
        studyDescription: "Abdomen",
        modality: 'CR'
    },

    {
        studyDescription: "Ankle",
        modality: 'DX'
    },
    {
        studyDescription: "Bone",
        modality: 'DX'
    },
    {
        studyDescription: "Both Hips",
        modality: 'DX'
    },
    {
        studyDescription: "Whole Spine",
        modality: 'DX'
    },
    {
        studyDescription: "Thoracic Spine",
        modality: 'DX'
    },
    {
        studyDescription: "Cervical Spine",
        modality: 'DX'
    },
    {
        studyDescription: "Lumbosacral Spine",
        modality: 'DX'
    },
    {
        studyDescription: "Chest",
        modality: 'DX'
    },
    {
        studyDescription: "Elbow",
        modality: 'DX'
    },
    {
        studyDescription: "Facial Bones",
        modality: 'DX'
    },
    {
        studyDescription: "Femur",
        modality: 'DX'
    },
    {
        studyDescription: "Foot",
        modality: 'DX'
    },
    {
        studyDescription: "Knee",
        modality: 'DX'
    },
    {
        studyDescription: "Ribs",
        modality: 'DX'
    },
    {
        studyDescription: "Hand",
        modality: 'DX'
    },
    {
        studyDescription: "Shoulder",
        modality: 'DX'
    },
    {
        studyDescription: "Skull",
        modality: 'DX'
    },
    {
        studyDescription: "Tibia-Fibula",
        modality: 'DX'
    },
    {
        studyDescription: "Wrist",
        modality: 'DX'
    },
    {
        studyDescription: "Finger",
        modality: 'DX'
    },
    {
        studyDescription: "Abdomen",
        modality: 'DX'
    },
    // CT
    {
        studyDescription: "Brain Plain",
        modality: 'CT'
    },
    {
        studyDescription: "Thorax",
        modality: 'CT'
    },
    {
        studyDescription: "HRCT Chest",
        modality: 'CT'
    },
    {
        studyDescription: "NCCT Head",
        modality: 'CT'
    },
    {
        studyDescription: "KUB",
        modality: 'CT'
    },
    {
        studyDescription: "CECT Whole Abdomen",
        modality: 'CT'
    },
    {
        studyDescription: "CECT Abdomen Pelvis",
        modality: 'CT'
    },
    {
        studyDescription: "ADRENAL PROTOCOL ",
        modality: 'CT'
    },
    {
        studyDescription: "ANKLE JOINT",
        modality: 'CT'
    },
    {
        studyDescription: "AORTA ANGIOGRAM",
        modality: 'CT'
    },
    {
        studyDescription: "ARM",
        modality: 'CT'
    },
    {
        studyDescription: "BIOPSY",
        modality: 'CT'
    },
    {
        studyDescription: "CERVICAL SPINE",
        modality: 'CT'
    },
    {
        studyDescription: "CAROTID AND CEREBRAL ANGIOGRAM",
        modality: 'CT'
    },
    {
        studyDescription: "CEREBRAL ANGIOGRAM",
        modality: 'CT'
    },
    {
        studyDescription: "CISTERNOGRAM",
        modality: 'CT'
    },
    {
        studyDescription: "CORONARY ANGIOGRAM - BYPASS GRAFT",
        modality: 'CT'
    },
    {
        studyDescription: "DORSAL LUMBAR SPINE",
        modality: 'CT'
    },
    {
        studyDescription: "ELBOW",
        modality: 'CT'
    },
    {
        studyDescription: "FACIAL BONES",
        modality: 'CT'
    },
    {
        studyDescription: "FOOT",
        modality: 'CT'
    },
    {
        studyDescription: "HAND",
        modality: 'CT'
    },
    {
        studyDescription: "KNEE JOINT",
        modality: 'CT'
    },
    {
        studyDescription: "KUB CONTRAST",
        modality: 'CT'
    },
    {
        studyDescription: "LUMBOSACRAL SPINE",
        modality: 'CT'
    },
    {
        studyDescription: "LEG",
        modality: 'CT'
    },
    {
        studyDescription: "LOWER ABDOMEN & PELVIS",
        modality: 'CT'
    },
    {
        studyDescription: "LOWER LIMBS",
        modality: 'CT'
    },
    {
        studyDescription: "MAXILLA & MANDIBLE",
        modality: 'CT'
    },
    {
        studyDescription: "NECK PLAIN",
        modality: 'CT'
    },
    {
        studyDescription: "ORBITS",
        modality: 'CT'
    },
    {
        studyDescription: "PELVIS & HIPS",
        modality: 'CT'
    },
    {
        studyDescription: "PELVIS (SOFT TISSUE)",
        modality: 'CT'
    },
    {
        studyDescription: "PERIPHERAL ANGIOGRAM LOWER LIMBS",
        modality: 'CT'
    },
    {
        studyDescription: "PERIPHERAL VENOGRAM BOTH UPPER LIMBS",
        modality: 'CT'
    },
    {
        studyDescription: "PNS WITH VERTIGO PROTOCOL",
        modality: 'CT'
    },
    {
        studyDescription: "PULMONARY ANGIOGRAM",
        modality: 'CT'
    },
    {
        studyDescription: "RENAL ANGIOGRAM",
        modality: 'CT'
    },
    {
        studyDescription: "SACRUM & COCCYX",
        modality: 'CT'
    },
    {
        studyDescription: "SACROILIAC JOINT",
        modality: 'CT'
    },
    {
        studyDescription: "SELLA",
        modality: 'CT'
    },
    {
        studyDescription: "SHOULDER",
        modality: 'CT'
    },
    {
        studyDescription: " STERNUM",
        modality: 'CT'
    },
    {
        studyDescription: "T M JOINTS",
        modality: 'CT'
    },
    {
        studyDescription: "TEMPORAL BONES",
        modality: 'CT'
    },
    {
        studyDescription: "THIGH",
        modality: 'CT'
    },
    {
        studyDescription: "THUMB",
        modality: 'CT'
    },
    {
        studyDescription: "SHOULDER (GLENOID INDEX)",
        modality: 'CT'
    },
    {
        studyDescription: "WRIST",
        modality: 'CT'
    },
    {
        studyDescription: "Angio of Aorta",
        modality: 'CT'
    },
    {
        studyDescription: "Angiography Arch of Aorta",
        modality: 'CT'
    },
    {
        studyDescription: "Both Lower Limbs Angio",
        modality: 'CT'
    },
    {
        studyDescription: "Both Upper Limbs Angio",
        modality: 'CT'
    },
    {
        studyDescription: "Mandible & T M joint",
        modality: 'CT'
    },
    {
        studyDescription: "Mesenteric Angio",
        modality: 'CT'
    },
    {
        studyDescription: "VIRTUAL COLONOSCOPY",
        modality: 'CT'
    },
    // MR
    {
        studyDescription: "ABDOMEN ",
        modality: 'MR'
    },
    {
        studyDescription: "ADRENAL REGION",
        modality: 'MR'
    },
    {
        studyDescription: "ANKLE JOINT",
        modality: 'MR'
    },
    {
        studyDescription: "AORTIC ANGIOGRAM - THORAX",
        modality: 'MR'
    },
    {
        studyDescription: "ARM",
        modality: 'MR'
    },
    {
        studyDescription: "AXILLA",
        modality: 'MR'
    },
    {
        studyDescription: "BOTH LOWER LIMBS",
        modality: 'MR'
    },
    {
        studyDescription: "BRACHIAL PLEXUS",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN - PARKINSONISM",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN & MR ANGIOGRAM & NECK ANGIO",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN & MR ANGIOGRAM",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN & MRA & MRV",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN & NECK ANGIOGRAM",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN & SELLA (CONTRAST)",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN ONLY MRA",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN SEIZURE PROTOCOL",
        modality: 'MR'
    },
    {
        studyDescription: " BRAIN STROKE PACKAGE",
        modality: 'MR'
    },
    {
        studyDescription: "BRAIN WITH CSF FLOW",
        modality: 'MR'
    },
    {
        studyDescription: "CARDIAC",
        modality: 'MR'
    },
    {
        studyDescription: "CERVICAL SPINE + WSS",
        modality: 'MR'
    },
    {
        studyDescription: "CERVICO VERTEBRAL JUNCTION",
        modality: 'MR'
    },
    {
        studyDescription: "CHEST",
        modality: 'MR'
    },
    {
        studyDescription: "CISTERNOGRAPHY",
        modality: 'MR'
    },
    {
        studyDescription: "CLAVICLE",
        modality: 'MR'
    },
    {
        studyDescription: "DORSAL SPINE + WSS",
        modality: 'MR'
    },
    {
        studyDescription: "ELBOW",
        modality: 'MR'
    },
    {
        studyDescription: "FACE",
        modality: 'MR'
    },
    {
        studyDescription: "FETAL - ABDOMEN",
        modality: 'MR'
    },
    {
        studyDescription: "FINGER",
        modality: 'MR'
    },
    {
        studyDescription: "FISTULOGRAM",
        modality: 'MR'
    },
    {
        studyDescription: "FOOT",
        modality: 'MR'
    },
    {
        studyDescription: "FOREARM",
        modality: 'MR'
    },
    {
        studyDescription: "GLUTEAL REGION",
        modality: 'MR'
    },
    {
        studyDescription: "HAND",
        modality: 'MR'
    },
    {
        studyDescription: "HIP JOINTS",
        modality: 'MR'
    },
    {
        studyDescription: "INNER EAR",
        modality: 'MR'
    },
    {
        studyDescription: "KNEE - PATELLAR  INSTABILITY",
        modality: 'MR'
    },
    {
        studyDescription: "LEG",
        modality: 'MR'
    },
    {
        studyDescription: "LUMBAR SPINE WITH WSS",
        modality: 'MR'
    },
    {
        studyDescription: "MAMMOGRAM",
        modality: 'MR'
    },
    {
        studyDescription: "MANDIBLE",
        modality: 'MR'
    },
    {
        studyDescription: "MRCP (PLAIN & CONTRAST)",
        modality: 'MR'
    },
    {
        studyDescription: "NECK ANGIOGRAM",
        modality: 'MR'
    },
    {
        studyDescription: "NECK",
        modality: 'MR'
    },
    {
        studyDescription: "ORAL CAVITY - TONGUE",
        modality: 'MR'
    },
    {
        studyDescription: "ORBIT",
        modality: 'MR'
    },
    {
        studyDescription: "PAROTID GLANDS",
        modality: 'MR'
    },
    {
        studyDescription: " PELVIS (FEMALE) FIBROID MAPPING",
        modality: 'MR'
    },
    {
        studyDescription: "PERINEUM",
        modality: 'MR'
    },
    {
        studyDescription: "PERIPHERAL ANGIOGRAM LOWER LIMBS NON CONTRAST",
        modality: 'MR'
    },
    {
        studyDescription: "PERIPHERAL VENOGRAM",
        modality: 'MR'
    },
    {
        studyDescription: "PNS",
        modality: 'MR'
    },
    {
        studyDescription: "PROSTATE",
        modality: 'MR'
    },
    {
        studyDescription: "RENAL ANGIO",
        modality: 'MR'
    },
    {
        studyDescription: " SACROCOCCYGEAL",
        modality: 'MR'
    },
    {
        studyDescription: "SACROLIC JOINTS",
        modality: 'MR'
    },
    {
        studyDescription: "SCAPULA",
        modality: 'MR'
    },
    {
        studyDescription: "SCROTUM",
        modality: 'MR'
    },
    {
        studyDescription: "SELLA",
        modality: 'MR'
    },
    {
        studyDescription: "SHOULDER - DISLOCATION",
        modality: 'MR'
    },
    {
        studyDescription: " SIALOGRAM",
        modality: 'MR'
    },
    {
        studyDescription: "SPECTROSCOPY",
        modality: 'MR'
    },
    {
        studyDescription: "STERNOCLAVICULAR JOINT",
        modality: 'MR'
    },
    {
        studyDescription: "STERNUM",
        modality: 'MR'
    },
    {
        studyDescription: "T M JOINTS",
        modality: 'MR'
    },
    {
        studyDescription: "THIGH",
        modality: 'MR'
    },
    {
        studyDescription: "THROACIC OUTLET",
        modality: 'MR'
    },
    {
        studyDescription: "THUMB",
        modality: 'MR'
    },
    {
        studyDescription: "VENOGRAM OF CENTRAL VEINS",
        modality: 'MR'
    },
    {
        studyDescription: "WHOLE SPINE SCREENING",
        modality: 'MR'
    },
    {
        studyDescription: "WHOLE SPINE",
        modality: 'MR'
    },
    {
        studyDescription: "WHOLE BODY (METASTASIS SCREENING)",
        modality: 'MR'
    },
    {
        studyDescription: "WRIST",
        modality: 'MR'
    },
    {
        studyDescription: " ADRENAL GLANDS",
        modality: 'MR'
    },
    {
        studyDescription: "IAM",
        modality: 'MR'
    },
    {
        studyDescription: "PRE-COCHLEAR IMPLANT ASSESSMENT",
        modality: 'MR'
    },
    {
        studyDescription: "THE PROSTATE ",
        modality: 'MR'
    },
    {
        studyDescription: "RT KNEE WITHOUT CONTRAST ",
        modality: 'MR'
    },
    // MG
    {
        studyDescription: "Mammogram Single",
        modality: 'MG'
    },
    {
        studyDescription: "Bilateral Mammogram",
        modality: 'MG'
    },
]

export default studyAndModality;