import React from 'react';
import { useModal, useReportEditor, useUser } from '../../contextProviders';
import PreviewTemplate from './PreviewTemplate';
import { Typography, Button } from '@ohif/ui';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";

const ReportTemplateList = ({ handleClick, handleDeleteTemplate, setDataTemplate ,setImageCapture, servicesManager}) => {
  const { usersList} = useUser();
  const { defaultTemplates } = useReportEditor();
  const { userAuthenticationService} = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const loginUserData = usersList?.filter(data => data.id === user.profile.sub)

  const loginUseremplateName = loginUserData?.map(data => data.attributes.templates).flat()

  const loginUserTemplateOption=defaultTemplates?.filter(data => loginUseremplateName.some(dat => dat === data.name))

  const templateOptions= loginUseremplateName?.includes('Default') ? defaultTemplates : loginUserTemplateOption


  const { show } = useModal();
  const tableHeaders = {
    name: 'Name',
    preview: 'Templates',
    update: 'Update',
    delete: 'Delete',
  };

  const keys = Object.keys(tableHeaders);

  const modelShow = templates => {
    show({
      content: PreviewTemplate,
      title: `RadiologistUsersCreateModel: Preview Template`,
      contentProps: { templates },
    });
  };

  const handleUpdated = (id, data) => {
    handleClick(true);
    setImageCapture(data.isCapture)
    setDataTemplate(data);
  };

  return (
    <>
      <div className="container relative mx-auto flex w-full flex-col pt-5">
        <div className="mb-5 flex flex-row justify-between">
          <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
            <Typography
              variant="h6"
              className="text-white"
              component={'h6'}
            >
              Report Templates
            </Typography>
          </div>
          <div className="flex h-[34px] flex-row items-center">
            <Typography
              variant="h6"
              className="text-primary-light cursor-pointer"
              component={'h6'}
              onClick={() => handleClick(true)}
            >
              Create New Report Template
            </Typography>
          </div>
        </div>
      </div>

      <div
        className="container m-auto mt-2 text-white"
        style={{ maxHeight: '400px', overflowX: 'auto', scrollbarColor: '#173239 transparent' }}
      >
        <table className="w-full table-auto">
          <thead className="bg-primary-dark border-secondary-light border">
            <tr>
              {keys.map(key => (
                <th
                  className="p-2"
                  key={key}
                >
                  {tableHeaders[key]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="mt-1">
            {templateOptions && templateOptions.length > 0 ? (
              templateOptions.map(instance => (
                <tr
                  key={instance.id}
                  className="template-row bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main  border text-center transition duration-300"
                >
                  {keys.map(key => (
                    <td
                      className='p-2'
                      key={`${instance.id}-${key}`}
                    >
                      {key === 'delete' ? (
                        <div
                          className={` cursor-pointer transition-all flex justify-center items-center hover:text-red-400 text-xl text-[#ff0000]`}
                          onClick={() => handleDeleteTemplate(instance.id)}
                        >
                          <RiDeleteBin6Fill />{' '}
                        </div>
                      ) : key === 'preview' ? (
                        <span
                          className=" cursor-pointer text-green-600"
                          onClick={() => modelShow(instance.templates)}
                        >
                          Preview Template
                        </span>
                      ) : key === 'update' ? (
                        <div
                        className={` flex justify-center items-center cursor-pointer transition-all text-xl hover:text-[#63b3ed] transition-all`}
                          onClick={() => handleUpdated(instance.id, instance)}
                        >
                          <FaEdit />
                        </div>
                      ) : (
                        instance[key]
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={keys.length}>No Templates Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReportTemplateList;
