// Reports.js
import React, { useEffect, useState } from 'react';
import { useAnalytics, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Pie, PieChart, Cell, Legend, LineChart, Line, AreaChart, Area } from 'recharts';
import InputDateRange from '../InputDateRange';
import Label from '../Label';
import moment from 'moment';

const Reports = () => {
    const {
        fetchStudyCount, studyCount,
        fetchReportsByPriority, reportsByPriority,
        fetchReportsByModality, reportsByModality,
        fetchReportsByDocumentStatus, reportsByDocumentStatus,
        fetchApprovedReportsByModality, approvedReportsByModality,
        fetchReportsByDate, reportsByDate,
        fetchReportsByWeek, reportsByWeek,
        fetchModalityWiseAverageTATs, modalityWiseAverageTATs,
        setStartDate, setEndDate,
        startDate, endDate,
    } = useAnalytics();

    const { show } = useSnackbar();
    const [error, setError] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState<any>({ startDate, endDate });

    const handleDateRangeFieldChange = ({ startDate, endDate }) => {
        const start_date = moment(startDate).format('YYYY-MM-DD');
        const end_date = moment(endDate).format('YYYY-MM-DD');
        setSelectedDateRange({ start_date, end_date });
        setStartDate(start_date);
        setEndDate(end_date);
    };

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

    const fetchData = (startDate, endDate) => {
        Promise.all([
            fetchStudyCount(),
            fetchReportsByPriority(startDate, endDate),
            fetchReportsByModality(startDate, endDate),
            fetchReportsByDocumentStatus(startDate, endDate),
            fetchApprovedReportsByModality(startDate, endDate),
            fetchReportsByDate(startDate, endDate),
            fetchReportsByWeek(startDate, endDate),
            fetchModalityWiseAverageTATs(startDate, endDate),
        ]).catch(error => setError(error));
    };

    const getLast7Days = () => {
        const today = new Date();
        const priorDate = new Date().setDate(today.getDate() - 7);

        return {
            startDate: new Date(priorDate).toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
        };
    };

    useEffect(() => {
        const { startDate, endDate } = getLast7Days();
        setSelectedDateRange({startDate, endDate})
        setStartDate(startDate);
        setEndDate(endDate);
        fetchData(startDate, endDate);
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchData(startDate, endDate);
            setSelectedDateRange({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (error) {
            show({ message: error.message, type: SnackbarTypes.ERROR });
        }
    }, [error, show]);

    const transformData = (dataObj) => {
        return dataObj
            ? Object.keys(dataObj).map(key => ({
                name: key || 'Unknown',   // Handle null or undefined keys
                value: parseInt(dataObj[key], 10),  // Convert count to number
            }))
            : [];
    };

    const reportsByPriorityData = transformData(reportsByPriority);
    const reportsByModalityData = transformData(reportsByModality);
    const reportsByDocumentStatusData = transformData(reportsByDocumentStatus);
    const approvedReportsByModalityData = transformData(approvedReportsByModality);
    const reportsByDateData = transformData(reportsByDate);
    const reportsByWeekData = transformData(reportsByWeek);
    const modalityWiseAverageTATsData = transformData(modalityWiseAverageTATs);

    const CustomTooltip = ({ payload, label }) => {
        if (payload.length > 0) {
            return (
                <div className="custom-tooltip" >
                    <p className="label" > {`Date: ${label}`
                    } </p>
                    < p className="value" > {`Value: ${payload[0].value}`
                    }</p>
                </div>
            );
        }
        return null;
    };

    const CustomTooltip2 = ({ payload }) => {
        if (payload.length > 0) {
            return (
                <div className="custom-tooltip" >
                    <p className="value" > {`${payload[0].name}: ${payload[0].value}`
                    } </p>
                </div>
            );
        }
        return null;
    };

    return (

        <div>
            <div className=' flex items-center justify-between my-5 gap-5'>
               <div className=' flex items-center gap-3'>
                    <Label className="mt-2" text={''}>Date Range : </Label>
                    <InputDateRange
                        id={'report-analutic'}
                        value={selectedDateRange}
                        onChange={handleDateRangeFieldChange}
                        setStudyFilterHeight={''}
                    />
               </div>
                < div style={{ textAlign: 'center' }}>
                    <p
                        className="inline-flex items-center rounded border border-[#3a3f99] bg-blue-400 py-2 px-4 font-semibold text-black transition-all opacity-80"
                    >
                        Study Count: {studyCount?.count || 0}
                    </p>

                </div>
            </div>




            < div className=' flex justify-between gap-5 mb-5'>
                <div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md'>
                    <p className=' text-[#5acce6]'>Study by Priority </p>
                    {
                        <ResponsiveContainer width="100%" height={400}>
                            {reportsByPriorityData.length > 0 ? (
                                <BarChart data={reportsByPriorityData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                    <XAxis dataKey="name" stroke="#fff"/>
                                    <YAxis stroke="#fff" allowDecimals={false} domain={[0, 'auto']} />
                                    <Tooltip content={CustomTooltip2} />
                                    <Bar dataKey="value" fill="#0088FEcd" />
                                </BarChart>
                            ) : (
                                <div className=' h-full flex justify-center items-center'>Data not available.</div>
                            )}
                        </ResponsiveContainer>
                    }
                </div>
                < div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md'>
                    <p className=' text-[#5acce6]'>Approved Study by Modality </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {approvedReportsByModalityData.length > 0 ? (
                            <BarChart data={approvedReportsByModalityData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 '/>
                                <XAxis dataKey="name" stroke="#fff"/>
                                <YAxis stroke="#fff" allowDecimals={false} domain={[0, 'auto']} />
                                < Tooltip content={CustomTooltip2} />
                                <Bar dataKey="value" fill="#00C49Fcd" />
                            </BarChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>

            < div className=' flex justify-between gap-5 mb-5'>
                < div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md'>
                    <p className=' text-[#5acce6]'>Study by Modality </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {reportsByModalityData.length > 0 ? (
                            <PieChart>
                                <Pie
                                    data={reportsByModalityData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={160}
                                    innerRadius={60}
                                    // label={(entry) => `${entry.name}: ${entry.value}`
                                    // }
                                >
                                    {
                                        reportsByModalityData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                </Pie>
                                < Tooltip content={CustomTooltip2} />
                                <Legend
                                    layout="horizontal"
                                    align="center"
                                    verticalAlign="bottom"
                                    wrapperStyle={{ bottom: 0, left: 0, right: 0, padding: 10 }}
                                    payload={
                                        reportsByModalityData.map((entry, index) => ({
                                            id: entry.name,
                                            type: 'square',
                                            value: `${entry.name}: ${entry.value}`,
                                            color: COLORS[index % COLORS.length],
                                        }))
                                    }
                                />
                            </PieChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
                <div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md'>
                    <p className=' text-[#5acce6]'>Study by Status </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {reportsByDocumentStatusData.length > 0 ? (
                            <PieChart>
                                <Pie
                                    data={reportsByDocumentStatusData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={160}
                                    innerRadius={60}
                                    // label={(entry) => `${entry.name}: ${entry.value}`
                                    // }
                                >
                                    {
                                        reportsByDocumentStatusData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                </Pie>
                                < Tooltip content={CustomTooltip2} />
                                <Legend
                                    layout="horizontal"
                                    align="center"
                                    verticalAlign="bottom"
                                    wrapperStyle={{ bottom: 0, left: 0, right: 0, padding: 10 }}
                                    payload={
                                        reportsByDocumentStatusData.map((entry, index) => ({
                                            id: entry.name,
                                            type: 'square',
                                            value: `${entry.name}: ${entry.value}`,
                                            color: COLORS[index % COLORS.length],
                                        }))
                                    }
                                />
                            </PieChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>

            < div className=' flex justify-between gap-5 mb-5'>
                <div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md'>
                    <p className=' text-[#5acce6]'>Study by Date </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {reportsByDateData.length > 0 ? (
                            <AreaChart data={reportsByDateData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }
                            }>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="name" stroke="#fff" />
                                <YAxis stroke="#fff" allowDecimals={false} domain={[0, 'auto']} />
                                < Tooltip content={CustomTooltip} />
                                <Line type="monotone" dataKey="value" stroke="#5acce6" />
                                <Area type="monotone" dataKey="value" stroke="#5acce6" fill="#FFBB28" />
                            </AreaChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>


            < div className=' flex justify-between gap-5 mb-5'>
                <div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md'>
                    <p className=' text-[#5acce6]'>Study by Week </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {reportsByWeekData.length > 0 ? (
                            <BarChart data={reportsByWeekData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }
                            }>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="name" stroke="#fff" />
                                <YAxis stroke="#fff" allowDecimals={false} domain={[0, 'auto']} />
                                < Tooltip content={CustomTooltip} />
                                <Bar dataKey="value" fill="#0088FEcd" />
                            </BarChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>

                < div className=' flex-1 bg-primary-dark  border-secondary-light border p-4 rounded-md'>
                    <p className=' text-[#5acce6]'>Modality Wise Average TAT (minutes) </p>

                    <ResponsiveContainer width="100%" height={400} >
                        {modalityWiseAverageTATsData.length > 0 ? (
                            <BarChart data={modalityWiseAverageTATsData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }
                            }>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="name" stroke="#fff" />
                                <YAxis stroke="#fff" allowDecimals={false} domain={[0, 'auto']} />
                                < Tooltip content={CustomTooltip2} />
                                <Bar dataKey="value" fill="#00C49Fcd" />
                            </BarChart>
                        ) : (
                            <div className=' h-full flex justify-center items-center'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>
        </div>

    );
};

export default Reports;
