import React, { useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import './DeputyAdmin.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputMultiSelect from '../InputMultiSelect';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { useSnackbar } from '../../contextProviders';

const DeputyAdminCreateModel = ({
  createUser,
  updateUser,
  fetchUsers,
  setUsersList,
  deputyAdminData,
  user,
  hide,
}) => {
  const initialValues = {
    firstName: deputyAdminData?.firstName || '',
    lastName: deputyAdminData?.lastName || '',
    username: deputyAdminData?.username || '',
    email: deputyAdminData?.email || '',
    mobileNumber: deputyAdminData?.attributes?.mobileNumber || '',
    password: deputyAdminData?.password || '',
    confirmPassword: '',
    title: deputyAdminData?.attributes?.title[0] || '',
    permission: deputyAdminData?.attributes?.permission || '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    username: Yup.string().required('User Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: !deputyAdminData ? Yup.string().required('Password is required') : Yup.string(),
    confirmPassword: !deputyAdminData
      ? Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
      : Yup.string(),
    mobileNumber: Yup.number().required('Mobile Number is required'),
    title: Yup.string().required('Title is required'),
    // permission: Yup.array().required('Select at least one Radiology Group is required'),
  });

  const options = [
    { label: 'Assign', value: 'Assign' },
    { label: 'Create Radiology Group', value: 'Create Radiology Group' },
    { label: 'Approve Report', value: 'Approve Report' },
    { label: 'Add Radiology Group Admin', value: 'Add Radiology Group Admin' },
    { label: 'Add Radiology Group Technologist', value: 'Add Radiology Group Technologist' },
    { label: 'Add Radiologist', value: 'Add Radiologist' },
    { label: 'Add Telerapp Manager', value: 'Add Telerapp Manager' },
    { label: 'Generate Invoice', value: 'Generate Invoice' },
    { label: 'Delete Study', value: 'Delete Study' },
    { label: 'Access Dashboard', value: 'Access Dashboard' },
    { label: 'Upload Study', value: 'Upload Study' },
    { label: 'Export Worklist ', value: 'Export Worklist ' },
  ];

  const initialSelectedOptions = deputyAdminData?.attributes?.permission || [];

  const { show } = useSnackbar();
  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions.flatMap(option => option.split(', '))
  );

  const handleSelectionChange = selected => {
    setSelectedOptions(selected);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (deputyAdminData) {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            roleType: ['DeputyAdmin'],
            mobileNumber: values.mobileNumber,
            permission: selectedOptions.join(', '),
            title: values.title.replace(/^"(.*)"$/, '$1'),
          },
          // credentials: [{ type: 'password', temporary: true, value: values.password }],
          // enabled: true,
          // emailVerified: true,
        };

        const response = await updateUser(deputyAdminData.id, data, user.access_token);
        if (response.status === 204) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: 'Success!',
            message: 'Successfully Deputy Admin Update',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
        }
        hide();
      } else {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            roleType: ['DeputyAdmin'],
            mobileNumber: values.mobileNumber,
            permission: [selectedOptions.join(', ')],
            title: [values.title],
          },
          credentials: [{ type: 'password', temporary: true, value: values.password }],
          enabled: true,
          emailVerified: true,
        };

        const response = await createUser(data, user.access_token);
        if (response.status === 201) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: 'Success!',
            message: 'Successfully Deputy Admin Created',
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: 'topRight',
          });
          hide();
        } else {
          const errorMessage = await response.json();
          setError(errorMessage.errorMessage);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="my-2 block">First Name</Label>
                <Field
                  type="text"
                  name="firstName"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Last Name</Label>
                <Field
                  type="text"
                  name="lastName"
                  as={Input}
                  placeholder="Please enter your last name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">User Name</Label>
                <Field
                  type="text"
                  name="username"
                  as={Input}
                  placeholder="Please enter your user name"
                  disabled={deputyAdminData ? true : false}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Mobile Number</Label>
                <Field
                  type="text"
                  name="mobileNumber"
                  as={Input}
                  placeholder="Please enter your mobile number"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Email</Label>
                <Field
                  type="text"
                  name="email"
                  as={Input}
                  placeholder="Please enter your email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Password</Label>
                <Field
                  type={passwordeye ? 'text' : 'password'}
                  name="password"
                  as={Input}
                  placeholder="Please enter your password"
                  disabled={deputyAdminData ? true : false}
                />
                <Icon
                  onClick={() => setpasswordeye(!passwordeye)}
                  name={passwordeye ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Confirm Password</Label>
                <Field
                  type={confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  as={Input}
                  placeholder="Please enter your confirm password"
                  disabled={deputyAdminData ? true : false}
                />
                <Icon
                  onClick={() => setConfirmPasswordeye(!confirmPassword)}
                  name={confirmPassword ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Title</Label>
                <Field
                  type="text"
                  name="title"
                  as={Input}
                  placeholder="Please enter your title"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block">Permission</Label>
                <Field
                  name="permission"
                  as={InputMultiSelect}
                  options={options}
                  value={selectedOptions}
                  onChange={handleSelectionChange}
                />

                <ErrorMessage
                  name="permission"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </div>
            <div className="flex">
              <Button
                className="mt-8"
                disabled={isSubmitting}
              >
                {deputyAdminData ? 'Update Deputy Admin' : 'Create Deputy Admin'}
              </Button>
              <Label className="my-2 mt-9 block h-3 pl-3 text-red-600	">{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default DeputyAdminCreateModel;
