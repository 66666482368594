import { fetchDefaultTemplates, createPatientReports, fetchPatientReports, generateReportPdf, updatePatientReports, generateReportWord, deletePatientReport, fetchReportSetting, createReportSetting, updateReportSetting, deleteReportSetting, createDefaultTemplates, deleteDefaultTemplates, updateDefaultTemplates } from './reportTemplateRequest'

import { fetchDocumentUpload, createDocument, updateDocument, deleteDocumentUrl, sendReportData, sendReportDataToWhastapp } from './documentUploadRequest';

import {
    fetchStudyCount, fetchReportsByPriorityAndDateRange, fetchReportsByModalityAndDateRange, fetchRadiologistActiveListData,
    fetchReportsByDocumentStatusAndDateRange,
    fetchApprovedReportsByModalityAndDateRange,
    fetchReportsByDateAnalysis,
    fetchReportsByModalityAndInstitute,
    fetchStudyStatusByPriorityAndDocumentStatus,
    fetchReportsByWeekAnlyt,
    fetchReportsByUserAndModality,
    fetchUserWeeklyPerformance,
    fetchModalityWiseAverageTAT,
    fetchInstituteWeeklyPerformanceData
} from './analyticsRequest';

export {
    fetchDefaultTemplates,
    createDefaultTemplates,
    createPatientReports,
    fetchPatientReports,
    generateReportPdf,
    updatePatientReports,
    generateReportWord,
    deletePatientReport,
    fetchReportSetting,
    createReportSetting,
    updateReportSetting,
    deleteReportSetting,
    deleteDefaultTemplates,
    updateDefaultTemplates,
    fetchDocumentUpload,
    createDocument,
    updateDocument,
    deleteDocumentUrl,
    sendReportData,
    sendReportDataToWhastapp,
    fetchStudyCount,
    fetchRadiologistActiveListData,
    fetchReportsByPriorityAndDateRange,
    fetchReportsByModalityAndDateRange,
    fetchReportsByDocumentStatusAndDateRange,
    fetchApprovedReportsByModalityAndDateRange,
    fetchReportsByDateAnalysis,
    fetchReportsByModalityAndInstitute,
    fetchStudyStatusByPriorityAndDocumentStatus,
    fetchReportsByWeekAnlyt,
    fetchReportsByUserAndModality,
    fetchUserWeeklyPerformance,
    fetchModalityWiseAverageTAT,
    fetchInstituteWeeklyPerformanceData
}
