import i18n from 'i18next';

const filtersMeta = [
  {
    name: 'checkBox',
    gridCol: 1,
  },
  {
    name: 'patientName',
    displayName: i18n.t('StudyList:PatientName'),
    inputType: 'Text',
    isSortable: true,
    gridCol: 3,
  },
  {
    name: 'tat',
    displayName: i18n.t('StudyList:TAT Counter'),
    inputType: 'None',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'studyTat',
    displayName: i18n.t('StudyList:Study TAT '),
    inputType: 'None',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'age',
    displayName: i18n.t('StudyList:Age'),
    inputType: 'None',
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'sex',
    displayName: i18n.t('StudyList:Sex'),
    inputType: 'MultiSelect',
    inputProps: {
      options: [
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' },
      ],
    },
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'dob',
    displayName: i18n.t('StudyList:DOB'),
    inputType: 'None',
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'uid',
    displayName: i18n.t('StudyList:UID'),
    inputType: 'Text',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'acquisitiondate',
    displayName: i18n.t('StudyList:Acquisition Date'),
    inputType: 'DateRange',
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'mrn',
    displayName: i18n.t('StudyList:MRN'),
    inputType: 'Text',
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'studyDate',
    displayName: i18n.t('StudyList:StudyDate'),
    inputType: 'DateRange',
    isSortable: true,
    gridCol: 3,
  },
  {
    name: 'priorites',
    displayName: i18n.t('StudyList:Priority'),
    inputType: 'MultiSelect',
    inputProps: {
      options: [
        { value: 'STAT', label: 'STAT' },
        { value: 'ASAP', label: 'ASAP' },
        { value: 'Routine', label: 'Routine' },
      ],
    },
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'description',
    displayName: i18n.t('StudyList:Description'),
    inputType: 'Text',
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'modalities',
    displayName: i18n.t('StudyList:Modality'),
    inputType: 'MultiSelect',
    inputProps: {
      options: [
        { value: 'AR', label: 'AR' },
        { value: 'ASMT', label: 'ASMT' },
        { value: 'AU', label: 'AU' },
        { value: 'BDUS', label: 'BDUS' },
        { value: 'BI', label: 'BI' },
        { value: 'BMD', label: 'BMD' },
        { value: 'CR', label: 'CR' },
        { value: 'CT', label: 'CT' },
        { value: 'CTPROTOCOL', label: 'CTPROTOCOL' },
        { value: 'DG', label: 'DG' },
        { value: 'DOC', label: 'DOC' },
        { value: 'DX', label: 'DX' },
        { value: 'ECG', label: 'ECG' },
        { value: 'EPS', label: 'EPS' },
        { value: 'ES', label: 'ES' },
        { value: 'FID', label: 'FID' },
        { value: 'GM', label: 'GM' },
        { value: 'HC', label: 'HC' },
        { value: 'HD', label: 'HD' },
        { value: 'IO', label: 'IO' },
        { value: 'IOL', label: 'IOL' },
        { value: 'IVOCT', label: 'IVOCT' },
        { value: 'IVUS', label: 'IVUS' },
        { value: 'KER', label: 'KER' },
        { value: 'KO', label: 'KO' },
        { value: 'LEN', label: 'LEN' },
        { value: 'LS', label: 'LS' },
        { value: 'MG', label: 'MG' },
        { value: 'MR', label: 'MR' },
        { value: 'M3D', label: 'M3D' },
        { value: 'NM', label: 'NM' },
        { value: 'OAM', label: 'OAM' },
        { value: 'OCT', label: 'OCT' },
        { value: 'OP', label: 'OP' },
        { value: 'OPM', label: 'OPM' },
        { value: 'OPT', label: 'OPT' },
        { value: 'OPTBSV', label: 'OPTBSV' },
        { value: 'OPTENF', label: 'OPTENF' },
        { value: 'OPV', label: 'OPV' },
        { value: 'OSS', label: 'OSS' },
        { value: 'OT', label: 'OT' },
        { value: 'PLAN', label: 'PLAN' },
        { value: 'PR', label: 'PR' },
        { value: 'PT', label: 'PT' },
        { value: 'PX', label: 'PX' },
        { value: 'REG', label: 'REG' },
        { value: 'RESP', label: 'RESP' },
        { value: 'RF', label: 'RF' },
        { value: 'RG', label: 'RG' },
        { value: 'RTDOSE', label: 'RTDOSE' },
        { value: 'RTIMAGE', label: 'RTIMAGE' },
        { value: 'RTINTENT', label: 'RTINTENT' },
        { value: 'RTPLAN', label: 'RTPLAN' },
        { value: 'RTRAD', label: 'RTRAD' },
        { value: 'RTRECORD', label: 'RTRECORD' },
        { value: 'RTSEGANN', label: 'RTSEGANN' },
        { value: 'RTSTRUCT', label: 'RTSTRUCT' },
        { value: 'RWV', label: 'RWV' },
        { value: 'SEG', label: 'SEG' },
        { value: 'SM', label: 'SM' },
        { value: 'SMR', label: 'SMR' },
        { value: 'SR', label: 'SR' },
        { value: 'SRF', label: 'SRF' },
        { value: 'STAIN', label: 'STAIN' },
        { value: 'TEXTUREMAP', label: 'TEXTUREMAP' },
        { value: 'TG', label: 'TG' },
        { value: 'US', label: 'US' },
        { value: 'VA', label: 'VA' },
        { value: 'XA', label: 'XA' },
        { value: 'XC', label: 'XC' },
      ],
    },
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'accession',
    displayName: i18n.t('StudyList:AccessionNumber'),
    inputType: 'Text',
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'institutionname',
    displayName: i18n.t('StudyList:Institution Name'),
    inputType: 'Select',
    isSortable: true,
    inputProps: {
      options: [],
    },
    gridCol: 2,
  },
  {
    name: 'attachment',
    displayName: i18n.t('StudyList:Attachment'),
    inputType: 'None',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'clinicalhistory',
    displayName: i18n.t('StudyList:Clinical History'),
    inputType: 'None',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'assign',
    displayName: i18n.t('StudyList:Assign'),
    inputType: 'Text',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'reportstatus',
    displayName: i18n.t('StudyList:Report Status'),
    inputType: 'MultiSelect',
    inputProps: {
      options: [
        { value: 'Final', label: 'Final' },
        { value: 'Draft', label: 'Draft' },
        { value: 'New', label: 'New' },
        { value: 'Approved', label: 'Approved' },
      ],
    },
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'document',
    displayName: i18n.t('StudyList:Document'),
    inputType: 'None',
    isSortable: true,
    gridCol: 2,
  },
  {
    name: 'instances',
    displayName: i18n.t('StudyList:Instances'),
    inputType: 'None',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'firstupdate',
    displayName: i18n.t('StudyList:First Image Received'),
    inputType: 'None',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'lastupdate',
    displayName: i18n.t('StudyList:Last Image Received'),
    inputType: 'None',
    isSortable: false,
    gridCol: 2,
  },
  {
    name: 'share',
    displayName: i18n.t('StudyList:Share'),
    inputType: 'None',
    isSortable: false,
    gridCol: 1,
  },
  // {
  //   name: 'delete',
  //   displayName: i18n.t('StudyList:Study Delete'),
  //   inputType: 'None',
  //   isSortable: false,
  //   gridCol: 2,
  // },

  {
    name: 'general',
    inputType: 'Text',
    displayName: i18n.t('StudyList:Search...'),
    gridCol: 2,
  },
];

export default filtersMeta;
