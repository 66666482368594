import React, { useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import InputMultiSelect from '../InputMultiSelect';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';

const RadiologistGroupModel = ({ user, data, createRadiologyGroup, fetchRadiologyGroup, updateRadiologyGroup, setRadiologyGroupList, fetchSingleRadiologyGroup, singleRadiologyGroupList, setSingleRadiologyGroupList, groupIds, radiologyGroupList, hide }) => {
  const { show } = useSnackbar();

  const initialValues = {
    radiologyGroupName: data.name || '',
    email: data?.attributes?.groupEmail || '',
    password: data?.password || '',
    confirmPassword: data?.password || '',
    mobileNumber: data?.attributes?.groupMobileNumber || '',
    permission: data?.attributes?.groupPermission || '',
    aeTitle: data?.attributes?.groupAETitle || '',
    ip: data?.attributes?.groupIP || '',
    portNumber: data?.attributes?.groupPortNumber || '',
    address: data?.attributes?.groupAddress || '',
  };


  // const validationSchema = Yup.object().shape({
  //   radiologyGroupName: Yup.string().required('Radiology group name is required'),
  //   email: Yup.string().email('Invalid email').required('Email is required'),
  //   mobileNumber: Yup.number().required('Mobile number is required'),
  //   aeTitle: Yup.array().required('AE Title is required'),
  //   ip: Yup.array().required('IP address is required'),
  //   portNumber: Yup.array().required('Port number is required'),
  //   address: Yup.array().required('Address is required'),
  //   password: !data ? Yup.string().required('Password is required') : Yup.string(),
  //   confirmPassword: !data ? Yup.string().required('Confirm Password is required')
  //   .oneOf([Yup.ref('password'), null], 'Passwords must match') : Yup.string(),
  //   // permission: Yup.array().required('Permission is required'),
  // });

  const options = [
    { label: 'ABC Group', value: 'ABC Group' },
    { label: 'Radiology Group-1', value: 'Radiology Group-1' },
    { label: 'Radiology Group-2', value: 'Radiology Group-2' },
  ];

  const initialSelectedOptions = data?.attributes?.groupPermission || [];

  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions.flatMap(option => option));
  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);

  const handleSelectionChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {

      if (data) {
      
        const dataDetails = {
          name: values.radiologyGroupName,
          attributes: {
            groupEmail: values.email === initialValues.email ? values.email : [values.email],
            groupMobileNumber:
              values.mobileNumber === initialValues.mobileNumber
                ? values.mobileNumber
                : [values.mobileNumber],
            groupAETitle:
              values.aeTitle === initialValues.aeTitle ? values.aeTitle : [values.aeTitle],
            groupAddress:
              values.address === initialValues.address ? values.address : [values.address],
            groupPortNumber:
              values.portNumber === initialValues.portNumber
                ? values.portNumber
                : [values.portNumber],
            groupPermission: [selectedOptions.join(', ')],
            groupIP: values.ip === initialValues.ip ? values.ip : [values.ip],
          },
          
        }
        const response = await updateRadiologyGroup(data.id, dataDetails, user.access_token)
        if (response.status === 204) {
          await fetchSingleRadiologyGroup(user.access_token, groupIds)
            .then(data => setSingleRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));

          await fetchRadiologyGroup(user.access_token)
            .then(data => setRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: "Success!",
            message: "Successfully Radiology Group Update",
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: "topRight"
          });
        }
        hide()
      } else {
        const dataDetails = {
          name: values.radiologyGroupName,
          attributes: {
            groupEmail: values.email === initialValues.email ? values.email : [values.email],
            groupMobileNumber:
              values.mobileNumber === initialValues.mobileNumber
                ? values.mobileNumber
                : [values.mobileNumber],
            groupAETitle:
              values.aeTitle === initialValues.aeTitle ? values.aeTitle : [values.aeTitle],
            groupAddress:
              values.address === initialValues.address ? values.address : [values.address],
            groupPortNumber:
              values.portNumber === initialValues.portNumber
                ? values.portNumber
                : [values.portNumber],
            groupPermission: [selectedOptions.join(', ')],
            groupIP: values.ip === initialValues.ip ? values.ip : [values.ip],
          },
          //   credentials: [{ type: 'password', temporary: true, value: values.password }],
          //   enabled: true,
          //   emailVerified: true,
        }
        const response = await createRadiologyGroup(dataDetails, user.access_token)
        if (response.status === 201) {
          let filteredGroupIds
          await fetchRadiologyGroup(user.access_token)
            .then((data) => {
              filteredGroupIds = data
                .filter(group => !group.error)
                .map(group => group.id);
              setRadiologyGroupList(data)
            })
            .catch(error => console.error('Error fetching users:', error));
  
          await fetchSingleRadiologyGroup(user.access_token, filteredGroupIds)
            .then(data => setSingleRadiologyGroupList(data))
            .catch(error => console.error('Error fetching users:', error));
          show({
            title: "Success!",
            message: "Successfully Radiology Group Created",
            type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
            position: "topRight"
          });
        }
        hide()
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <section className="w-full">

      <Formik initialValues={initialValues}  onSubmit={handleSubmit} >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label className="my-2 block">Radiology Group Name</Label>
                <Field type="text" name="radiologyGroupName" as={Input} placeholder="Please enter your radiology group name" />
                <ErrorMessage name="radiologyGroupName" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block">Email</Label>
                <Field type="text" name="email" as={Input} placeholder="Please enter email" />
                <ErrorMessage name="email" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block">Mobile Number</Label>
                <Field type="text" name="mobileNumber" as={Input} placeholder="Please enter mobile number" />
                <ErrorMessage name="mobileNumber" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block">AE Title</Label>
                <Field type="text" name="aeTitle" as={Input} placeholder="Please enter your AE title" />
                <ErrorMessage name="aeTitle" component="div" className="text-red-600" />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Password</Label>
                <Field type={passwordeye ? 'text' : 'password'} name="password" as={Input} placeholder="Please enter your password" disabled={data ? true : false} />
                <Icon
                  onClick={() => setpasswordeye(!passwordeye)}
                  name={passwordeye ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage name="password" component="div" className="text-red-600" />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Confirm Password</Label>
                <Field type={confirmPassword ? 'text' : 'password'} name="confirmPassword" as={Input} placeholder="Please enter your confirm password" disabled={data ? true : false}/>
                <Icon
                  onClick={() => setConfirmPasswordeye(!confirmPassword)}
                  name={confirmPassword ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage name="confirmPassword" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block">IP</Label>
                <Field type="text" name="ip" as={Input} placeholder="Please enter your IP" />
                <ErrorMessage name="ip" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block">Port Number</Label>
                <Field type="text" name="portNumber" as={Input} placeholder="Please enter your port number" />
                <ErrorMessage name="portNumber" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block">Address</Label>
                <Field type="text" name="address" as={Input} placeholder="Please enter your address" />
                <ErrorMessage name="address" component="div" className="text-red-600" />
              </div>
              <div className='from__dropdown'>
                <Label className="my-2 block"> Permission</Label>
                <Field
                  name="permission"
                  as={InputMultiSelect}
                  options={options}
                  value={selectedOptions}
                  onChange={handleSelectionChange}
                />

                <ErrorMessage name="permission" component="div" className="text-red-600" />
              </div>
            </div>
            <div className="flex">
              <Button className="mt-8" disabled={isSubmitting}>
                {data ? 'Update Radiologist Group' : 'Create Radiologist Group'}
              </Button>
              <Label className="my-2 block h-3 text-red-600 mt-9 pl-3	">{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default RadiologistGroupModel;
