import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from '../../contextProviders';
import { GoDotFill } from 'react-icons/go';
import { Loader } from 'rsuite';
import 'rsuite/Loader/styles/index.css';


const RadiologistUserActiveDetailsModel = ({ radiologistData, fetchSessionsRadiologistUser, user, radiologistUser }) => {


  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSessionsRadiologistUser(user.access_token, radiologistUser)
      .then(data => setSession(data))
      .catch(error => console.error('Error fetching radiology user session:', error));

      const timer = setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => clearTimeout(timer);
  }, []);

  const activeUsernames = Array.from(new Set(session.flat().map(user => user.username))); // Convert Set to array

  const activeRadiologistData = activeUsernames.map(username => {
    // Get the modalities data for the username or an empty object if not found
    const modalities = radiologistData[username]?.modalities || {};

    // Combine modality names into a single string
    const combinedModality = Object.keys(modalities).join(', ');

    // Calculate total approved and pending counts using reduce
    const totalApproved = Object.values(modalities).reduce((sum, modality) => sum + modality.approved, 0);
    const totalPending = Object.values(modalities).reduce((sum, modality) => sum + modality.pending, 0);

    // Return the structured data for each active radiologist
    return {
      username,
      modality: combinedModality,
      approved: totalApproved,
      pending: totalPending,
    };
  });


  const tableHeaders = {
    liveStatus: 'Live Status',
    radiologist: 'Radiologist',
    modality: 'Modality',
    approved: 'Aprroved',
    pending: 'Pending'
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);


  return (
    <section className="w-full">
      {activeRadiologistData.length > 0 ? (
        <table className="container m-auto mt-2 table-auto text-white w-full">
          <thead className="bg-primary-dark border-secondary-light border">
            <tr>
              {values.map(tableHead => (
                <th className="p-2" key={tableHead}>
                  {tableHead}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {activeRadiologistData.map((user, userIndex) => (
              <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                {keys.map(key => (<td className="p-2"
                  key={key}>
                  {key === 'liveStatus' ? (
                    <span>
                      <GoDotFill
                        fontSize={37}
                        style={{ color: 'lime' }}
                        className="ml-20"
                      />
                    </span>
                  ) : key === 'radiologist' ? (
                    <span className="cursor-pointer">
                      {user?.username}
                    </span>
                  ) : key === 'modality' ? (
                    <span className="cursor-pointer">{user.modality}</span>
                  ) : key === 'approved' ? (
                    <span className="cursor-pointer">
                      {user.approved}
                    </span>
                  ) : key === 'pending' ? (
                    <span className="cursor-pointer">
                      {user.pending}
                    </span>
                  ) : (
                    user[key]
                  )}
                </td>))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className=' w-full h-48 bg-primary-dark border-secondary-light flex items-center justify-center border p-4'>
          {loading ? (
            <Loader size="sm" color='#fff' content="loading..." />
          ) : (
            'User not available'
          )}
        </div>
      )
      }
    </section>
  );
};

export default RadiologistUserActiveDetailsModel;
