import React, { useEffect, useState } from 'react';
import { Button, Input, Label } from '@ohif/ui';
import './ReportTemplate.css';
import CustomTemplates from './CustomTemplates';

const CreateReportTemplate = ({
  handleClick,
  data,
  handleSubmit,
  error,
  dataTemplate,
  checkedAttributesItems,
  setCheckedAttributesItems,
  templateName,
  setTemplateName,
  templates,
  setEditorData,
  selectCustomTemplate,
  setSelectCustomTemplate,
  imageCapture,
  setImageCapture
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const handleNameClick = async name => {
    const updatedCheckedItems = {
      ...checkedAttributesItems,
      [name]: !checkedAttributesItems[name],
    };
    setCheckedAttributesItems(updatedCheckedItems);
  };

  const handleSelectAllChange = e => {
    const checked = e.target.checked;
    const updatedCheckedItems = {};

    data.forEach(item => {
      updatedCheckedItems[item.name] = checked;
    });

    setCheckedAttributesItems(updatedCheckedItems);
    setSelectAllChecked(checked);
  };

  const handleSelectAllClick = () => {
    const updatedCheckedItems = {};
    const newSelectAllChecked = !selectAllChecked;

    data.forEach(item => {
      updatedCheckedItems[item.name] = newSelectAllChecked;
    });

    setCheckedAttributesItems(updatedCheckedItems);
    setSelectAllChecked(newSelectAllChecked);
  };

  useEffect(() => {
    if (dataTemplate) {
      const attributesObj = JSON.parse(dataTemplate?.templateAttributes[0]);
      setCheckedAttributesItems(attributesObj);
      setTemplateName(dataTemplate.name);
      setEditorData(dataTemplate?.customTemplate);
    } else {
      setCheckedAttributesItems({});
      setTemplateName('');
      setEditorData('');
    }
  }, [dataTemplate]);


  useEffect(() => {
    if(!dataTemplate){
      const initialCheckedItems = {};
      data.forEach(item => {
        initialCheckedItems[item.name] = item.isChecked; 
      });
      setCheckedAttributesItems(initialCheckedItems);
    }
  }, []);

  return (
    <>
      <div className="my-2 w-11/12">
        <Label className="my-2 block">Template Name</Label>
        <Input
          className="border-primary-main bg-black"
          type="text"
          name="name"
          containerClassName="mr-2"
          value={templateName}
          onChange={e => setTemplateName(e.target.value)}
          placeholder="Please enter template name"
        />
        <Label
          className="my-2 ml-2 mb-5 block h-3 pl-3	"
          style={{ color: 'red' }}
        >
          {error}
        </Label>
      </div>
      <div className="ml-3.5 grid grid-cols-4 items-center gap-4">
        <div
          className="template-checkbox"
          onClick={handleSelectAllClick}
        >
          <input
            type="checkbox"
            checked={selectAllChecked}
            onChange={handleSelectAllChange}
            className="cursor-pointer"
          />
          <span onClick={handleSelectAllClick}>Select All</span>
        </div>
      </div>

      <div className="ml-3.5 grid grid-cols-4	 items-center gap-4">
        {data.map((data, index) => (
          <div
            className="template-checkbox"
            key={index}
            onClick={() => handleNameClick(data.name)}
          >
            <input
              type="checkbox"
              checked={checkedAttributesItems[data.name] || false}
              onChange={() => handleNameClick(data.name)}
              className="cursor-pointer"
            />
            <span onClick={() => handleNameClick(data.name)}>{data.label}</span>
          </div>
        ))}
        <div
            className="template-checkbox"
            onClick={() => setImageCapture(!imageCapture)}
          >
            <input
              type="checkbox"
              checked={imageCapture}
              onChange={() => setImageCapture(!imageCapture)}
              className="cursor-pointer"
            />
            <span onClick={() => setImageCapture(!imageCapture)}>Image Capture</span>
          </div>
      </div>
      {!dataTemplate?.customTemplate && (
        <>
          <div
            className="template-checkbox mt-5 ml-3.5 w-3/6"
            onClick={() => setSelectCustomTemplate(!selectCustomTemplate)}
          >
            <input
              type="checkbox"
              checked={selectCustomTemplate}
              onChange={() => setSelectCustomTemplate(!selectCustomTemplate)}
              className="cursor-pointer"
            />
            <span onClick={() => setSelectCustomTemplate(!selectCustomTemplate)}>
              {' '}
              Do you want to create a customized template
            </span>
          </div>
        </>
      )}

      {dataTemplate?.customTemplate && (
        <CustomTemplates
          templates={templates}
          setEditorData={setEditorData}
          dataTemplate={dataTemplate?.customTemplate}
        />
      )}

      {selectCustomTemplate && (
        <CustomTemplates
          templates={templates}
          setEditorData={setEditorData}
          dataTemplate={dataTemplate?.customTemplate}
        />
      )}

      <div className="flex">
        <Button
          className="mt-8"
          onClick={handleSubmit}
        >
          {dataTemplate ? 'Update Report Template' : 'Create Report Template'}
        </Button>

        <Button
          className="mt-8 ml-2"
          onClick={() => {
            handleClick(false);
            setSelectCustomTemplate(false);
          }}
        >
          {'Cancel'}
        </Button>
      </div>
    </>
  );
};

export default CreateReportTemplate;
