import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LegacyButton from '../LegacyButton';
import LegacyButtonGroup from '../LegacyButtonGroup';
import Typography from '../Typography';
import Select from '../Select';
import Button from '../Button';
import { useAnalytics, useModal, useReportEditor, useUser } from '../../contextProviders';
import RadiologistUserActiveDetailsModel from './RadiologistUserActiveDetailsModel';
import { RxCross2 } from 'react-icons/rx';
import { Loader } from 'rsuite';
import { GoDotFill } from 'react-icons/go';

const StudyListPagination = ({ servicesManager, onChangePage, currentPage, perPage, numOfStudies, onChangePerPage }) => {
  const { t } = useTranslation('StudyList');
  const { show, hide } = useModal();
  const { radiologistData } = useAnalytics();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // const togglePopup = () => {
  //   setIsPopupOpen(!isPopupOpen);
  // };

  const togglePopup = (e) => {
    e.stopPropagation(); // Stop the click event from propagating
    setIsPopupOpen(prev => !prev); // Toggle the popup state
  };

  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const {
    usersList,
    fetchSessionsRadiologistUser,
  } = useUser();

  const radiologistUserLists =
    usersList &&
    usersList?.filter(user => user?.attributes?.roleType?.includes('Radiologist'));

  const radiologistUser = radiologistUserLists && radiologistUserLists?.map(data => data.id);

  const navigateToPage = page => {
    const toPage = page < 1 ? 1 : page;
    onChangePage(toPage);
  };

  const ranges = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  const [selectedRange, setSelectedRange] = useState({
    value: '25',
    label: '25'
  });

  const onSelectedRange = selectedRange => {
    setSelectedRange(selectedRange);
    onChangePerPage(selectedRange.value);
  };

  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSessionsRadiologistUser(user.access_token, radiologistUser)
      .then(data => setSession(data))
      .catch(error => console.error('Error fetching radiology user session:', error));

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);


  const activeUsernames = Array.from(new Set(session.flat().map(user => user.username))); // Convert Set to array

  const activeRadiologistData = activeUsernames.map(username => {
    // Get the modalities data for the username or an empty object if not found
    const modalities = radiologistData[username]?.modalities || {};

    // Combine modality names into a single string
    const combinedModality = Object.keys(modalities).join(', ');

    // Calculate total approved and pending counts using reduce
    const totalApproved = Object.values(modalities).reduce((sum, modality) => sum + modality.approved, 0);
    const totalPending = Object.values(modalities).reduce((sum, modality) => sum + modality.pending, 0);


    return {
      username,
      modality: combinedModality,
      approved: totalApproved,
      pending: totalPending,
    };
  });


  const tableHeaders = {
    liveStatus: 'Live Status',
    radiologist: 'Radiologist',
    modality: 'Modality',
    approved: 'Aprroved',
    pending: 'Pending'
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  const handlemodelShow = () => {
    show({
      content: RadiologistUserActiveDetailsModel,
      title: `Radiologist List`,
      contentProps: {
        fetchSessionsRadiologistUser,
        radiologistData,
        user,
        radiologistUser,
      },
    });
  };

  return (
    <div className="bg-black py-10">
      <div className="px-8 relative">
        {/* Ensure the popup can position relative to this container */}
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <Select
                id="rows-per-page"
                className="border-primary-main relative mr-3 w-24"
                options={ranges}
                value={selectedRange}
                isMulti={false}
                isClearable={false}
                isSearchable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                onChange={onSelectedRange}
              />
              <Typography className="text-base opacity-60">{t('Results per page')}</Typography>
            </div>
            <div className="flex items-center">
              <Typography variant="h6" className="text-primary-light">
                {`${t('Number of studies')}: `}
              </Typography>
              <Typography variant="h6" className="mr-2" data-cy={'num-studies'}>
                {numOfStudies}
              </Typography>
            </div>
          </div>
          <div className="">
            <div className="flex items-center">
              <Typography className="mr-4 text-base opacity-60">
                {t('Page')} {currentPage}
              </Typography>
              <LegacyButtonGroup>
                <LegacyButton
                  size="initial"
                  className="px-4 py-2 text-base"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(1)}
                >
                  {`<<`}
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="py-2 px-2 text-base"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(currentPage - 1)}
                >
                  {t('Previous')}
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="py-2 px-4 text-base"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(currentPage + 1)}
                  disabled={numOfStudies <= perPage * currentPage}
                >
                  {t('Next')}
                </LegacyButton>
              </LegacyButtonGroup>

              <div className="ml-2">
                <Button onClick={togglePopup}>Radiologist</Button>
              </div>

              {isPopupOpen && (
                <div
                  className="fixed right-10 top-80 bg-primary-dark p-6 rounded-lg shadow-lg z-50 border border-secondary-light"
                  style={{ minWidth: '400px', height: '500px', overflowY: 'auto' }}
                // Fixed height and enable scrolling
                >
                  <header className=" bg-primary-dark flex items-center rounded-tl rounded-tr pb-2">
                    <h6 className="  text-primary-light m-0 flex grow text-xl leading-tight !leading-[1.2]">
                      Radiologist List
                    </h6>
                    <RxCross2 onClick={togglePopup} className=' text-primary-light text-xl' />
                  </header>

                  {activeRadiologistData.length > 0 ? (
                    <table className="container m-auto mt-2 table-auto text-white w-full">
                      <thead className="bg-primary-dark border-secondary-light border">
                        <tr>
                          {values.map(tableHead => (
                            <th className="p-2 text-lg" key={tableHead}>
                              {tableHead}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {activeRadiologistData.map((user, userIndex) => (
                          <tr className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border text-center transition duration-300">
                            {keys.map(key => (<td className="p-2"
                              key={key}>
                              {key === 'liveStatus' ? (
                                <span>
                                  <GoDotFill
                                    fontSize={37}
                                    style={{ color: 'lime' }}
                                    className="ml-5"
                                  />
                                </span>
                              ) : key === 'radiologist' ? (
                                <span className="cursor-pointer">
                                  {user?.username}
                                </span>
                              ) : key === 'modality' ? (
                                <span className="cursor-pointer">{user.modality}</span>
                              ) : key === 'approved' ? (
                                <span className="cursor-pointer">
                                  {user.approved}
                                </span>
                              ) : key === 'pending' ? (
                                <span className="cursor-pointer">
                                  {user.pending}
                                </span>
                              ) : (
                                user[key]
                              )}
                            </td>))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className=' w-full h-48 bg-primary-dark border-secondary-light flex items-center justify-center border p-4  text-white'>
                      {loading ? (
                        <Loader size="sm" color='#fff' content="loading..." />
                      ) : (
                        'User not available'
                      )}
                    </div>
                  )
                  }

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StudyListPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
};

export default StudyListPagination;
