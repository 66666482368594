import React from 'react';
import Button from '../Button';
import { BsPersonFillAdd, BsPersonFillDash } from "react-icons/bs";

const AddRadiologistModel = ({
  user,
  token,
  hide,
  usersList,
  modalities,
  studyInstanceUid,
  handleSelectedUserDataChange,
  handleSelectedUserRemove,
  patientReportsDetails,
}) => {
  const permissions = user?.profile?.permission?.[0]
    ?.split(',')
    .map(permission => permission.trim());
  const hasAssignPermission =
    permissions?.includes('Assign') ||
    token?.realm_access?.roles.includes('super-admin') ||
    token?.realm_access?.roles.includes('deputy-admin');

  const radiologyGroupName = user?.profile?.radiologyGroup;
  const TelerappUser = token?.realm_access?.roles.includes('super-admin') || token?.realm_access?.roles.includes('deputy-admin') || user?.profile?.roleType === 'TelerappManager' || user?.profile?.roleType === 'DeputyAdmin' ;

  const tableHeaders = {
    username: 'User Name',
    fullName: 'Full Name',
    assignTime: 'Assign Time',
    assignBy: 'Assign By',
    ...(hasAssignPermission && { action: 'Assign / Unassign' }),
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  const radiologistUserLists =
    usersList &&
    usersList?.filter(user => {
      return TelerappUser
        ? user?.attributes?.roleType?.includes('Radiologist') || user?.attributes?.roleType?.includes('QaUsers') || user?.attributes?.roleType?.includes('Physician')
        : user?.attributes?.radiologyGroup?.includes(radiologyGroupName) ||
        user?.attributes?.radiologyGroup?.includes('Default') || user?.attributes?.roleType?.includes('QaUsers') ;
    });

  const findPatientReportsDetails = patientReportsDetails?.filter(
    user => user.study_UIDs === studyInstanceUid
  );
  const assignNameConavat = findPatientReportsDetails?.map(item => item.assign?.map(JSON.parse));

  const handleAssignRemoveButtonClick = instance => {
    // Check if the user is already assigned
    const isAssigned = assignNameConavat?.some(item =>
      item?.some(e => e.assign_name === instance.username)
    );

    // If assigned, remove user; otherwise, assign user
    if (isAssigned) {
      handleSelectedUserRemove({ assignNameConavat, studyInstanceUid, instance });
    } else {
      const currentTime = new Date();
      handleSelectedUserDataChange({ userName: instance.username, currentTime, studyInstanceUid, modalities });
    }

    hide();
  };

  const timeHandler = instance => {
    const findAssignName = assignNameConavat?.find(item =>
      item?.some(e => e.assign_name === instance.username)
    );

    return findAssignName?.map(
      e =>
        e.assign_name === instance?.username &&
        new Date(e.assign_time).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        })
    );
  };
  const assignBy = instance => {
    const findAssignName = assignNameConavat?.find(item =>
      item?.some(e => e.assign_name === instance.username)
    );
    const foundElement = findAssignName?.find(e => e.assign_name === instance.username);

    return foundElement?.assign_by;
  };

  return (
    <section className="w-full">
      {usersList?.length <= 0 ? (
        <span className="flex justify-center">No Members Exiting</span>
      ) : (
        <table className="container m-auto mt-2 table-auto text-white">
          <thead className="bg-primary-dark border-secondary-light border">
            <tr>
              {values.map((tableHead, index) => (
                <th
                  className="p-2"
                  key={index}
                >
                  {tableHead}
                </th>
              ))}
            </tr>
          </thead>
          {radiologistUserLists && radiologistUserLists.length <= 0 ? (
            <span className="absolute  flex h-96 w-5/12 items-center justify-end">
              No Radiologist Users Exiting
            </span>
          ) : (
            <tbody className="mt-1">
              {radiologistUserLists &&
                radiologistUserLists?.map((instance, index) => (
                  <tr
                    key={index}
                    className="bg-primary-dark border-secondary-light hover:border-secondary-light hover:bg-secondary-main cursor-pointer border transition duration-300"
                  >
                    {keys.map((key, i) => (
                      <td
                        className="p-2 text-center"
                        key={i}
                      >
                        {key === 'fullName' ? (
                          `${instance.firstName} ${instance.lastName}`
                        ) : key === 'assignTime' ? (
                          <span>{timeHandler(instance)}</span>
                        ) : key === 'assignBy' ? (
                          <span>{assignBy(instance)}</span>
                        ) : hasAssignPermission && key === 'action' ? (
                          <div
                            className="cursor-pointer flex justify-center items-center "
                            onClick={() => handleAssignRemoveButtonClick(instance)}
                          >
                            {assignNameConavat?.find(item =>
                              item?.some(e => e.assign_name === instance.username)
                            )
                              ? <BsPersonFillDash className=' text-2xl hover:text-blue-300 text-[#63b3ed] transition-all' />
                              : <BsPersonFillAdd className=' text-2xl hover:text-blue-400  transition-all' />}
                          </div>
                        ) : (
                          instance[key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      )}
    </section>
  );
};

export default AddRadiologistModel;
